.video-text {
  --aspect-ratio: 56.25%;
  --aspect-ratio-frac: 0.5625;

  position: relative;

  overflow: hidden;
}

.video-text__gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background: radial-gradient(
    51.82% 50% at 50% 50%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.video-text__container {
  display: grid;
  grid-template-columns: 57% 1fr;
  grid-gap: 32px;
  align-items: center;

  padding: 88px 0;
}
@media (--tablet) {
  .video-text__container {
    grid-template-columns: 1fr;
    grid-gap: 52px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.video-text__content {
  width: 100%;
  align-self: center;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
}

.video-text__image {
  position: relative;
  width: 100%;
  height: 360px;
}

.video-text__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-text.video-text--is-mobile .video-text__play__wrap,
.video-text.video-text--ready.video-text--is-mobile .video-text__play__wrap {
  opacity: 0;

  transition: 300ms opacity;
}

.video-text__video.video-text--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.video-text .text {
  max-width: 64ch;
}
* + .video-text .text {
  margin-top: 12px;
}

.video-text__video {
  position: relative;
  height: 0;
  padding-bottom: var(--aspect-ratio);
}

.video-text__play__wrap {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.video-text__player__static {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  background-color: black;
}
.video-text--fullscreen .video-text__player__static {
  object-fit: contain;
}

.video-text__video__wrap {
  position: absolute;
  top: 0;
  right: -3px;
  bottom: 0;
  left: -3px;

  overflow: hidden;
  border-radius: 5px;

  background-color: var(--primary-600);
}

.video-text__play {
  display: block;
  width: 100%;
  height: 100%;

  color: #ffffff;

  transition: 400ms opacity, 400ms transform;

  will-change: opacity, transform;
}
.video-text__video.video-text--started.video-text--playing .video-text__play {
  transform: scale(0.8);
  opacity: 0;

  transition: 300ms opacity, 300ms transform;

  pointer-events: none;
}

.video-text__video.video-text--started.video-text--playing
  .video-text__player__blur {
  width: calc(88px * 0.8);
  height: calc(88px * 0.8);
  opacity: 0;

  transition: 300ms all;

  pointer-events: none;
}

.video-text__player__blur {
  position: absolute;
  top: 50%;
  left: 50%;

  border-radius: 50%;

  transform: translate(-55%, -50%);

  width: 88px;
  height: 88px;
  transition: 300ms all;

  backdrop-filter: blur(4px);

  background-color: rgba(255, 255, 255, 0.2);
}

.video-text--hover .video-text__player__blur {
  width: calc(88px * 1.1);
  height: calc(88px * 1.1);

  transition: 300ms all;
}

.video-text__play > button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
}
.video-text__play > button:focus {
  outline: none;
}
.video-text__video.video-text--started.video-text--playing
  .video-text__play
  > button {
  cursor: default;
}

@media (--mobile) {
  .video-text__play > button {
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 17px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.video-text__play > button > .video-text__play__img {
  --size: 36px;

  display: flex;
  width: var(--size);
  height: var(--size);

  transition: 200ms transform;
}
@media (--mobile) {
  .video-text__play > button > .video-text__play__img {
    --size: 28px;
  }
}

.video-text__play > button > .video-text__play__img img {
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: 200ms transform;
}
.video-text__play > button:hover > .video-text__play__img {
  transform: scale(1.1);

  transition: 200ms transform;
}
.video-text__play > button:active > .video-text__play__img img {
  transform: scale(0.95);

  transition: 100ms transform;
}

.video-text__fullscreen {
  transition: 300ms opacity, 300ms transform;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: scale(1);

  padding: 20px;

  z-index: 4;
}

.video-text__fullscreen .video-text__fullscreen__img img {
  width: 20px;
  height: 20px;
}

.video-text__fullscreen:hover > .video-text__fullscreen__img {
  transform: scale(1.1);

  transition: 200ms transform;
}

.aspect-ratio.video-text--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
}

.aspect-ratio.video-text--fullscreen .video-text__player {
  box-shadow: none;
}

.aspect-ratio.video-text--fullscreen .video-text__video__wrap {
  border-radius: 0;
}

.aspect-ratio.video-text--fullscreen .aspect-ratio__wrap {
  width: 100%;
  max-width: calc(100vh * (1 / (var(--aspect-ratio-frac))));

  background: black;
}

.aspect-ratio.video-text--fullscreen .aspect-ratio__wrap__wrap {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);

  background: black;
}

.aspect-ratio.video-text--fullscreen .video-text__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid black;
  background: black;
}

.video-text__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.video-text__wrap {
  align-items: center;
  height: 100%;
}

.video-text__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);
  border-radius: 5px;
}

.video-text__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video-text__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
  border-radius: 5px;
}

.video-text__player__cover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  content: '';
}

@media (--header-breakpoint) {
  .video-text__player__cover > img {
    bottom: 0;

    height: 100%;
  }

  .video-text__fullscreen {
    display: none;
  }
}

.video-text__video.video-text--ready .video-text__video__wrap {
  opacity: 1;

  transition: 200ms opacity;
}

.video-text__video__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  height: 300px;

  transition: 500ms opacity;

  content: '';
}
.video-text__video.video-text--started.video-text--playing
  .video-text__video__wrap::after {
  transition: 2000ms opacity;
}

.video-text__video__wrap > * {
  position: relative;

  top: 50%;

  transform: translateY(-50%);
}

.video-text__control {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
}

.video-text__control__play-pause {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: default;
}
.video-text__video.video-text--started.video-text--playing
  .video-text__control__play-pause {
  z-index: 3;
}
.video-text__control__play-pause:focus {
  outline: none;
}

.video-text__content__item {
  gap: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.video-text__content__item__data {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: var(--secondary-500);
}

.video-text__content__item__data strong {
  font-weight: 400;
  font-size: 34px;
  line-height: 1.3;
  text-align: center;
}
@media (--mobile) {
  .video-text__content__item__data strong {
    font-size: 26px;
  }
}

.video-text__content__item__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;

  max-width: 21ch;
  margin: 0 auto;
}

.video-text__content__item a {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.video-text__content__item a * {
  width: 100%;
}

.video-text__content__item a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}
