.footer {
  --skyline-height: 224px;
  --skyline-reflection-height: 86px;
}
@media (--tablet) {
  .footer {
    --skyline-height: 180px;
    --skyline-reflection-height: 69px;
  }
}
@media (--mobile) {
  .footer {
    --skyline-height: 120px;
    --skyline-reflection-height: 46px;
  }
}

.footer--article {
  margin-top: 0;
}

.footer__wrap {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #021526 0%, #052542 100%);
}

.footer__skyline {
  height: var(--skyline-height);
  margin-top: 60px;

  background-image: url('/backgrounds/skyline3.png');
  background-size: auto var(--skyline-height);
  background-repeat: repeat-x;
  background-position: bottom center;
  user-select: none;
  pointer-events: none;
}
@media (--mobile) {
  .footer__skyline {
    margin-top: 32px;
  }
}

.footer--article .footer__skyline {
  display: none;
}

.footer__skyline--reflection {
  position: absolute;
  top: 0;

  width: 100%;
  height: var(--skyline-reflection-height);

  background-image: url('/backgrounds/skyline-reflection.png');
  background-size: auto var(--skyline-reflection-height);
  background-position: top center;
}

.footer__inner {
  padding-top: 40px;
}

.footer__inner__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media (--tablet) {
  .footer__inner__top {
    flex-direction: column;
    gap: 24px;
  }
}
.footer__infos {
  width: 272px;

  margin-right: 17px;
}

@media (--mobile) {
  .footer__infos {
    width: 100%;
  }
}

.footer__infos__logo {
  display: block;
  width: 177.41px;
  height: 44px;
}

.footer__infos__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__infos__address {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: 0.01em;
  max-width: 20ch;
}

* + .footer__infos__address {
  margin-top: 16px;
}

.footer__infos__address * + p {
  margin-top: 6px;
}

.footer__infos__address strong {
  color: var(--white);
  font-weight: 400;
}

.footer__social {
  display: flex;
  align-items: center;
  list-style: none;
}

* + .footer__social {
  margin-top: 16px;
}

.footer__social li {
  height: 20px;
}

.footer__social li a {
  height: 100%;
  padding-right: 24px;
}

@media (--mobile) {
  .footer__social li a:not(:first-of-type) {
    padding: 0 12px;
  }
}

.footer__inner__top__left {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (--mobile) {
  .footer__inner__top__left {
    flex-direction: column;
    gap: 24px;
  }
}

.footer__inner__top__right {
  display: flex;
  align-items: center;
  max-width: 370px;
  margin-top: 10px;
}

@media (--tablet) {
  .footer__inner__top__right {
    max-width: 100%;
  }
}

.footer__partners {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}
@media (--mobile) {
  .footer__partners {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
}

.footer__partners__item {
  width: 100%;
  height: 100%;
}

.footer__partners__item:last-of-type {
  margin-top: 17px;
}
@media (--mobile) {
  .footer__partners__item:last-of-type {
    margin-top: 0;
  }
}

.footer__partners__item p {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #ffffff;

  opacity: 0.7;
}

.footer__partners__item__images {
  display: flex;
  align-items: center;
  margin-top: 14px;
  width: 100%;
  height: 100%;
  max-height: 53px;
}

.footer__partners__item__images img:nth-of-type(2) {
  margin-left: 24px;
}

.footer__partners__item__images svg {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  user-select: none;
}

.footer__social li a img {
  height: 100%;
  transition: 200ms transform;
  user-select: none;
}
.footer__social li a:hover img {
  transform: scale(1.15);
}
.footer__social li a:active img {
  transform: scale(1);
  transition: 100ms transform;
}

.footer__column__item__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  text-transform: uppercase;
}
@media (--mobile) {
  .footer__column__item__title {
    font-size: 18px;
  }
}

* + .footer__column__item__links {
  margin-top: 12px;
}

.footer__column__item__links p,
.footer__column__item__links a {
  display: block;

  line-height: 1.2;
  color: var(--white);
  font-weight: 400;
  font-size: 14px;

  color: var(--white);
}

.footer__column__item__links a,
.footer__column__item__links p {
  --vertical-padding: 6px;

  padding-top: var(--vertical-padding);
  text-decoration: none;
  letter-spacing: 0.01em;

  opacity: 0.7;
  transition: 200ms opacity;
}
.footer__column__item__links a:hover {
  opacity: 1;
  text-decoration: underline;
}
@media (--mobile) {
  .footer__column__item__links a,
  .footer__column__item__links p {
    --vertical-padding: 6px;
    font-size: 16px;
  }
}

.footer * + .newsletter-form {
  margin-top: 20px;
}

.footer__inner__bottom {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(5, 1fr);
}
@media (--tablet) {
  .footer__inner__bottom {
    margin-top: 34px;
    padding-top: 34px;
    grid-template-columns: repeat(2, 1fr);

    row-gap: 32px;
  }
}
@media (--mobile) {
  .footer__inner__bottom {
    margin-top: 24px;
    padding-top: 24px;
  }
}

.footer__bottom {
  background-color: var(--blue-500);
  margin-top: 56px;
}

.footer__bottom__container {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (--tablet) {
  .footer__bottom__container {
    padding-top: 24px;
    flex-direction: column;
  }
}
@media (--mobile) {
  .footer__bottom__container {
    margin-top: 32px;
    padding-bottom: 32px;
  }
}

.footer__bottom__copyright {
  color: var(--primary-600);
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  letter-spacing: 0.01em;
}
@media (--mobile) {
  .footer__bottom__copyright {
    font-size: 14px;
  }
}

.footer__bottom__links {
  --gap: 32px;

  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
@media (--tablet) {
  * + .footer__bottom__links {
    --gap: 16px;
  }
}
@media (--mobile) {
  .footer__bottom__links {
    justify-content: center;
  }
}

* + .footer__bottom__links {
  margin-left: 32px;
}
@media (--tablet) {
  * + .footer__bottom__links {
    margin-top: 12px;
    margin-left: 0;
  }
}

.footer__bottom__links li a,
.footer__bottom__links li button {
  --vertical-padding: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;

  letter-spacing: 0.01em;

  display: block;
  padding-top: var(--vertical-padding);
  padding-right: calc(var(--gap) / 2);
  padding-bottom: var(--vertical-padding);
  padding-left: calc(var(--gap) / 2);

  color: var(--primary-600);
  text-decoration: none;
  transition: 200ms opacity;
}
@media (--mobile) {
  .footer__bottom__links li a,
  .footer__bottom__links li button {
    font-size: 14px;
  }
}
.footer__bottom__links li a:hover,
.footer__bottom__links li button:hover {
  text-decoration: underline;
}
