.multi-image-text {
  padding: 56px 0;
  background: radial-gradient(
    82.6% 79.7% at 50% 50%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.multi-image-text__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
}

.multi-image-text__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 56px;
  min-height: 363px;

  @media (--mobile) {
    grid-template-columns: 1fr;
    gap: 48px;
  }
}

.multi-image-text__container-reverse > *:nth-child(1) {
  order: 2;

  @media (--mobile) {
    order: 1;
  }
}

.multi-image-text__container-reverse > *:nth-child(2) {
  order: 1;

  @media (--mobile) {
    order: 2;
  }
}

.multi-image-text__image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.35) 100%
  );
}

.multi-image-text__text {
  color: var(--white);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.62;
  letter-spacing: 0.32px;
}

.title + .multi-image-text__text {
  padding-top: 32px;
}

.multi-image-text__text a {
  color: var(--white);
}
