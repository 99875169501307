* + .newsletter {
  margin-top: 112px;
}

@media (--mobile) {
  * + .newsletter {
    margin-top: 0;
  }
}

.newsletter {
  position: relative;
}

.newsletter__bats,
.newsletter__bats--reverse {
  position: absolute;
  top: 32px;

  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

@media (--mobile) {
  .newsletter__bats,
  .newsletter__bats--reverse {
    display: none;
  }
}

.newsletter__bats {
  right: -81px;
}

.newsletter__bats--reverse {
  transform: scaleX(-1);

  left: -81px;
}
.newsletter__content {
  padding: 48px 0;
}

.newsletter__content .newsletter-form__message {
  margin-top: 16px;
  text-align: center;
}

.newsletter__social {
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
}
@media (--mobile) {
  .newsletter__social {
    margin: auto;

    justify-content: center;
  }
}

* + .newsletter__social {
  margin-top: 16px;
}

.newsletter__social li {
  height: 32px;
}

.newsletter__social li a {
  height: 100%;
  padding-right: 24px;
}

.newsletter__social li a img {
  height: 100%;
  width: 100%;
  transition: 200ms transform;
  user-select: none;
}
.newsletter__social li a:hover img {
  transform: scale(1.15);
}
.newsletter__social li a:active img {
  transform: scale(1);
  transition: 100ms transform;
}

* + .newsletter__title {
  margin-top: 24px;
}

.newsletter__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
}
@media (--mobile) {
  .newsletter__title h2 {
    font-size: 26px;
  }
}

* + .newsletter__description {
  margin-top: 16px;
}

.newsletter__description {
  max-width: 52ch;
  margin-left: auto;
  margin-right: auto;
}

.newsletter__description p {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

@media (--mobile) {
  .newsletter__description p {
    font-size: 14px;
    line-height: 20px;
  }
}

.newsletter .newsletter__form {
  max-width: 450px;
  margin: auto;
}

@media (--mobile) {
  .newsletter .newsletter__form {
    max-width: 100%;
  }
}

.newsletter circle {
  fill: var(--white);
  stroke: var(--white);
}
