.multiple-icons {
  --icon-size: 70px;

  position: relative;
  background-color: white;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.multiple-icons .max-width {
  display: flex;
  justify-content: center;
}

@media (--mobile) {
  .multiple-icons .max-width {
    display: block;
    padding: 100px 0;
  }
}

.multiple-icons__background-left {
  position: absolute;

  left: 0;
  bottom: 0;
  width: 500px;
  height: 143px;
  object-fit: contain;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.multiple-icons__background-right {
  position: absolute;

  right: 0;
  bottom: 0;
  width: 360px;
  height: 208px;
  object-fit: contain;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (--mobile) {
  .multiple-icons__background-left {
    display: none;
  }
}

* + .multiple-icons__item {
  margin-left: 140px;
}

.multiple-icons__item img {
  width: var(--icon-size);
  height: var(--icon-size);
}

.multiple-icons__item h3 {
  color: var(--dark);
  font-weight: 500;
  font-size: 16px;
  margin-top: 27px;
}

@media (--mobile) {
  .multiple-icons__item {
    margin: 0;
    text-align: center;
  }

  * + .multiple-icons__item {
    margin-top: 40px;
  }

  .multiple-icons__item h3 {
    margin-top: 16px;
  }
}
