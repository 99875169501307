* + .gold-ticket {
  margin-top: 112px;
}

.gold-ticket__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  padding: 48px 40px;

  background-color: var(--primary-500);
  border-radius: 5px;
}

@media (--tablet) {
  .gold-ticket__container {
    grid-template-columns: 1fr;
    padding: 28px 20px;
  }
}

.gold-ticket__image {
  position: absolute;
  top: 50%;
  left: 0;

  transform: translate(-12%, -50%);
  width: 100%;

  max-width: 534px;
  height: 100%;
  max-height: 390px;
}

@media (--tablet) {
  .gold-ticket__image {
    position: relative;
    top: 0;
    transform: translate(0, 0);
    max-width: 534px;
    max-height: 390px;
  }
}

.gold-ticket__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.gold-ticket__content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  grid-column-start: 2;
  grid-column-end: 3;
}

.gold-ticket__title + .gold-ticket__paragraph {
  margin-top: 16px;
}

.gold-ticket__title h2 {
  max-width: 32ch;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;

  color: var(--white);
}

.gold-ticket__title h2 strong {
  color: var(--secondary-500);
}

.gold-ticket__paragraph p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.9);
}

.gold-ticket__paragraph p + p {
  margin-top: 16px;
}

.gold-ticket__paragraph p strong {
  font-weight: 600;
  font-size: 16px;
  color: var(--secondary-500);
}

.gold-ticket__paragraph p strong + strong {
  margin-top: 16px;
}

.gold-ticket__paragraph p + ul {
  margin-top: 16px;
}

.gold-ticket__paragraph ul {
  list-style: none;
}
.gold-ticket__paragraph li {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.02em;

  padding-left: 7px;

  max-width: 35ch;
}

.gold-ticket__paragraph li::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  content: '';

  transform: translateX(-24px);

  background-image: url(/icons/check.svg);
  background-position: left;
  background-repeat: none;
}

.gold-ticket__paragraph li {
  margin-left: 26px;
}

.gold-ticket__paragraph li + li {
  margin-top: 8px;
}

.gold-ticket__content .button {
  margin-top: 32px;
}
