.two-columns {
  --vertical-padding: 140px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  background-color: #040814;
  background-image: radial-gradient(
    80% 80% at 50% 50%,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
@media (--tablet) {
  .two-columns {
    --vertical-padding: 64px;
  }
}
@media (--mobile) {
  .two-columns {
    --vertical-padding: 32px;

    background-image: radial-gradient(
      120% 60% at 50% 50%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.two-columns .max-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 90px;
}
@media (--tablet) {
  .two-columns .max-width {
    grid-gap: 32px;
  }
}
@media (--mobile) {
  .two-columns .max-width {
    grid-template-columns: 1fr;
  }
}

.two-columns__content {
  color: var(--white, #fff);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.32px;
}
* + .two-columns__content {
  margin-top: 32px;
}
@media (--mobile) {
  * + .two-columns__content {
    margin-top: 16px;
  }
}

.two-columns__content p + p {
  margin-top: 16px;
}

.two-columns__content a {
  color: var(--blue-500);
  text-decoration: none;
}
.two-columns__content a:hover {
  text-decoration: underline;
}
