.journey__background--plan {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.journey__background--plan img {
  position: absolute;
  user-select: none;
}

.journey__background--plan__ellipse {
  width: 210px;
  right: 14%;
  top: -20px;
}

.journey__background--plan__frise {
  width: 550px;
  right: 45%;
  bottom: 100px;
  z-index: 3;
}

@media (max-width: 1600px) {
  .journey__background--plan__frise {
    right: 800px;
  }
}

.journey__background--plan__parcours {
  width: 570px;
  right: 8%;
  top: 330px;
}

.journey__background--plan__resto {
  width: 320px;
  right: 31%;
  top: 90px;
}

@media (max-width: 500px) {
  .journey__background--plan__ellipse {
    width: 150px;
    right: 5%;
    top: -20px;
  }

  .journey__background--plan__frise {
    width: 350px;
    right: 10%;
    bottom: 50px;
  }

  .journey__background--plan__parcours {
    width: 300px;
    right: 50%;
    left: auto;
    top: unset;
    bottom: 130px;
  }

  .journey__background--plan__resto {
    width: 250px;
    left: 5%;
    top: 50px;
  }
}
