@custom-media --hero-breakpoint (max-width: 1090px);

.hero {
  --slide-transition: 0.8s;
  --slide-width: 594px;
  --offset: 48px;
  position: relative;
}

@media (max-width: 1200px) {
  .hero {
    --slide-width: 100%;
    --offset: 0;
  }
}

.hero__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(10, 13, 23, 0), #0a0d17 92.33%),
    radial-gradient(
      47.31% 182.77% at 71.81% 50%,
      rgba(0, 0, 0, 0.75) 0,
      transparent 100%
    );

  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.hero__gradient__violet {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  background-image: radial-gradient(
    10.57% 6.48% at 10.31% 30.9%,
    rgba(255, 255, 255, 0.3) 50.6%,
    rgba(0, 0, 0, 0) 100%
  );
}

@media (min-width: 1650px) {
  .hero__gradient__violet {
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    background-image: radial-gradient(
      10.7% 10.48% at 10.31% 29.9%,
      rgba(255, 255, 255, 0.3) 5%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@media (--mobile) {
  .hero__gradient {
    max-height: 383px;
  }
  .hero__gradient__violet {
    display: none;
  }
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__background__images {
  list-style: none;
}

.hero__background__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.hero__content {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
  user-select: none;
}

.hero__content--active {
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
}

@media (--mobile) {
  .hero__background__image {
    max-height: 383px;
  }
}
.js-can-use-transitions .hero__background__image {
  transition: opacity 500ms;
}
.hero__background__image--active {
  opacity: 1;
}

.hero__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
}

.hero__container {
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: calc(var(--header-height) + 64px);
  padding-bottom: 64px;

  margin-top: var(--sup-header-height);

  gap: 14px;

  z-index: 1;
}
.js-can-use-transitions .hero__container {
  transition-duration: 400ms;
  transition-property: all;
}

.main--flash-info .hero__container {
  padding-top: calc(var(--header-height) + var(--height-flash-info) + 64px);
}

@media (max-width: 1200px) {
  .hero__container {
    gap: 0;

    grid-template-columns: 1.9fr 2fr;
  }
}
@media (--hero-breakpoint) {
  .hero__container {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-height) + 60px);
  }

  .main--flash-info .hero__container {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-height) + var(--height-flash-info) + 60px);
  }
}
@media (--tablet) {
  .hero__container {
    padding-top: calc(var(--header-height) + 32px);
    gap: 0;
  }

  .main--flash-info .hero__container {
    padding-top: calc(var(--header-height) + var(--height-flash-info) + 32px);
  }
}
@media (--mobile) {
  .hero__container {
    padding-top: var(--header-height);
    padding-bottom: 0;
  }
  .main--flash-info .hero__container {
    padding-top: calc(var(--header-height) + var(--height-flash-info-mobile));
  }
}

.hero__left {
  position: relative;

  width: 100%;
  align-self: flex-start;
  width: var(--slide-width);
}

@media (--mobile) {
  .hero__left {
    overflow: hidden;
    width: 100%;
  }

  .hero__buttons .button--theme-outline {
    margin-bottom: 5px;
  }
}

@media (--hero-breakpoint) {
  .hero__left {
    width: 100%;
  }
}

.hero__heading {
  font-weight: 700;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 56px;
  line-height: 1.25;
  text-transform: uppercase;
}
@media (--tablet) {
  .hero__heading {
    font-weight: 400;
    font-size: 40px;
  }
}
@media (--mobile) {
  .hero__heading {
    font-size: 28px;
  }
}

.hero__heading__sup {
  --color: var(--blue-500);

  position: relative;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 44px;
  line-height: 1.5;
  text-transform: uppercase;
  color: var(--color);
}
.hero__heading__sup--violet {
  --color: var(--violet-500);
}
.hero__heading__sup--raspberry {
  --color: var(--raspberry-500);
}
.hero__heading__sup--green {
  --color: var(--green-500);
}
@media (--mobile) {
  .hero__heading__sup {
    font-weight: 400;
    font-size: 22px;
    letter-spacing: 0.05em;
  }
}

.hero__description a {
  color: var(--white);
}

.hero__description {
  font-family: var(--font-family);
  max-width: 42ch;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: var(--white);
  line-height: 140%;
}
* + .hero__description {
  margin-top: 12px;
}

@media (--mobile) {
  .hero__description {
    margin: 32px auto 0 auto;
  }
}

.hero__buttons {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
@media (--mobile) {
  .hero__buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
  }
}

.hero__buttons > * + * {
  margin-left: 16px;
}
@media (--mobile) {
  .hero__buttons > * + * {
    margin-top: 16px;
    margin-left: 0;
  }
}

.button--right-arrow-circle {
  margin-right: 16px;
}

.hero__model {
  position: relative;
  opacity: 0;
}

.hero__model__container,
.hero__content__container__wrap__item {
  width: var(--slide-width);
  padding: 0 var(--offset) 0 0;
  scroll-snap-align: center;
}

@media (max-width: 1200px) {
  .hero__model__container,
  .hero__content__container__wrap__item {
    width: 100%;
    padding: 0;
  }
}

@media (--mobile) {
  .hero__model {
    display: none;
  }
}

.hero__content__container__wrap__item {
  position: absolute;
  top: 0;
  left: 0;

  opacity: 0;
  transition: opacity 500ms;
  pointer-events: none;
}

.hero__content__container__wrap__item--active {
  opacity: 1;
  pointer-events: auto;
}

.hero__content__container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.hero--no-slice .hero__content__container {
  position: relative;
}
@media (--mobile) {
  .hero__content__container {
    position: static;
    text-align: center;
  }
}

.hero__content__container::-webkit-scrollbar {
  display: none;
}

.hero__content__container__wrap {
  position: absolute;
  top: 0;
  scroll-snap-type: x mandatory;
}
.hero--no-slice .hero__content__container__wrap {
  position: relative;
}
@media (--mobile) {
  .hero__content__container__wrap {
    position: relative;
  }
}

@media (--mobile) {
  .hero__content {
    text-align: center;
    justify-content: center;
  }

  .hero__content .hero__title {
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    letter-spacing: 0.05em;
  }

  .hero__content .hero__description {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }
}

/* RIGHT */

.hero__right {
  --hero-slider-gap: 16px;

  --hero-slider-width-1: 174.86px;
  --hero-slider-width-2: 192.34px;

  --hero-slider-height-1: 205.54px;
  --hero-slider-height-2: 242.85px;

  position: relative;
  display: flex;
  align-self: flex-end;
  height: var(--hero-slider-height-2);
  margin-top: 230px;
  user-select: none;
}
@media (--hero-breakpoint) {
  .hero__right {
    margin-top: 32px;
  }
}
@media (--tablet) {
  .hero__right {
    margin-top: 0;
  }
}
@media (--mobile) {
  .hero__right {
    position: relative;

    display: none;
    width: 100%;
    height: auto;
    margin-top: 52px;
  }
}

.hero__right__content {
  position: relative;

  display: flex;
  align-items: flex-end;
  text-align: right;
}
@media (--tablet) {
  .hero__right__content {
    text-align: left;
  }
}
@media (--mobile) {
  .hero__right__content {
    z-index: 1;
  }
}

.hero__right__content__item {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(20%);
  pointer-events: none;
}
@media (--mobile) {
  .hero__right__content__item {
    position: relative;
    transform: translateY(0);
  }
}

.js-can-use-transitions .hero__right__content__item {
  transition: opacity 200ms, transform 200ms;
}
.hero__right__content__item--active {
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}
.js-can-use-transitions .hero__right__content__item--active {
  transition: opacity 800ms, transform 800ms;
}

.hero__right__content__item .ruler {
  margin-left: auto;
}
@media (--tablet) {
  .hero__right__content__item .ruler {
    margin-left: 0;
  }
}

.hero__right__content * + .button {
  margin-top: 16px;
}
.hero__right__content .button .button__text {
  font-weight: 400;
}

.hero__right__slider {
  position: relative;

  width: 100%;
  height: var(--hero-slider-height-2);
  grid-gap: 16px;
}
@media (--mobile) {
  .hero__right__slider {
    height: 100%;
  }
}

.hero__right__slider__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;

  width: 1000px;
}

@media (--hero-breakpoint) {
  .hero__right__slider__wrap {
    transform: translateY(-30%);
  }
}
@media (--mobile) {
  .hero__right__slider__wrap {
    position: relative;

    display: flex;
    flex-direction: column;
    width: 100%;
    transform: none;
    align-items: center;
  }
}

.hero__right__slider__wrap::-webkit-scrollbar {
  display: none;
}

@media (--mobile) {
  .hero__right__slider__wrap::after {
    content: '\A0';
    display: block;
    width: 0;
    margin-left: var(--page-left-padding);
  }
}

.hero__right__slider__slide {
  position: relative;

  width: var(--hero-slider-width-1);
  height: var(--hero-slider-height-1);

  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: translateZ(0px);
}

.hero__right__slider__slide__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: radial-gradient(
    170.53% 115.37% at 50% 50%,
    rgba(4, 8, 20, 0.9) 0%,
    rgba(4, 8, 20, 0) 100%
  );
}
.js-can-use-transitions .hero__right__slider__slide {
  transition: 300ms;
}
.hero__right__slider__slide--current {
  width: var(--hero-slider-width-2);
  height: var(--hero-slider-height-2);
}

@media (--mobile) {
  .hero__right__slider__slide,
  .hero__right__slider__slide--current {
    height: 204px;
    width: 100%;
  }
}

.hero__right__slider__slide--current .hero__right__slider__slide__gradient {
  background: radial-gradient(
    170.53% 115.37% at 50% 50%,
    rgba(4, 8, 20, 0.7) 0%,
    rgba(4, 8, 20, 0) 100%
  );
}

@media (--mobile) {
  .hero__right__slider__slide--current .hero__right__slider__slide__gradient,
  .hero__right__slider__slide .hero__right__slider__slide__gradient {
    background: radial-gradient(
      123.31% 83.42% at 50.28% 76.28%,
      #040814 0%,
      rgba(4, 8, 20, 0) 100%
    );
  }
}

* + .hero__right__slider__slide {
  margin-left: var(--hero-slider-gap);
}

@media (--mobile) {
  * + .hero__right__slider__slide {
    margin-left: 0;
    margin-top: 32px;
  }
}

.hero__right__slider__slide__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;

  transform: translate(-50%, -50%);

  width: 80%;
}

.hero__right__slider__slide .hero__right__slider__slide__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

@media (--mobile) {
  .hero__right__slider__slide__logo {
    max-width: 167.69px;
  }
}

.hero__right__slider__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.hero__right__slider__tags {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  width: fit-content;

  padding: 7px 10px;
}
.hero__right__slider__tags p {
  white-space: nowrap;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  transition: all 300ms;
}

.hero__right__slider__slide--current .hero__right__slider__tags p {
  font-size: 17px;
  line-height: 17px;
}

@media (--mobile) {
  .hero__right__slider__tags p,
  .hero__right__slider__slide--current .hero__right__slider__tags p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
.hero__right__slider__tags--violet {
  background-color: var(--violet-500);
}
.hero__right__slider__tags--raspberry {
  background-color: var(--raspberry-500);
}
.hero__right__slider__tags--green {
  background-color: var(--green-500);
}

.hero__right__slider__slide__link {
  position: absolute;
  top: 0;
  bottom: -14px;
  left: 0;
  right: 0;

  z-index: 999;

  color: transparent;
  user-select: none;
}

.hero__right__slider__slide__link:hover {
  cursor: pointer;
}

.hero__right__slider__slide__link::before {
  content: '';

  position: absolute;
  top: 0;
  left: -9px;

  width: calc((var(--hero-slider-gap) / 2) + 2px);
  height: 100%;
}

.hero__right__slider__slide__link::after {
  content: '';

  position: absolute;
  top: 0;
  right: -9px;

  width: calc((var(--hero-slider-gap) / 2) + 2px);
  height: 100%;
}
