.article-content-top {
  color: var(--primary-600);
  margin-top: 40px;
}

.article-content-top .max-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}

@media (--mobile) {
  .article-content-top .max-width {
    display: block;
  }

  * + .article-content-top__right {
    margin-top: 40px;
  }
}

.article-content-top * + p {
  margin-top: 25px;
}

.article-content-top p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.article-content-top li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.article-content-top li + li {
  margin-top: 20px;
}

.article-content-top a {
  color: var(--blue-500);
  text-decoration: none;
}

.article-content-top a:hover {
  text-decoration: underline;
}

.article-content-top img {
  width: 100%;
}

.article-content-top * + h2 {
  margin-top: 32px;
}

.article-content-top h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  text-transform: uppercase;
}

.article-content-top h2:before {
  display: block;
  content: '';
  height: 7px;
  width: 30%;
  background-color: var(--blue-500);
  margin-bottom: 16px;
}

.article-content-top h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
}
.article-content-top * + h3 {
  margin-top: 24px;
}
