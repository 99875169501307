.menu {
  --header-padding-top: 24px;
  --gap: 24px;
  --menu-width: 290px;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: none;

  background-color: var(--white);

  will-change: clip-path;
  max-width: var(--menu-width);
  max-height: 100vh;
  margin-left: auto;
  padding: 0;
}

.menu__close-zone {
  position: absolute;
  top: 0;
  right: var(--menu-width);
  bottom: 0;
  height: 100vh;
  width: calc(100vw - var(--menu-width));

  cursor: pointer;

  background-color: var(--primary-500);
  opacity: 0.7;
}

.hidden {
  visibility: hidden;
  display: none;
}

.menu.menu--open {
  display: block;
}

.menu__header {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0;
  height: 20px;
  padding: 26px 25px 0 25px;
  margin-right: auto;
  margin-left: auto;

  color: #000;
  font-size: 17px;

  z-index: 5;
}

.menu__burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 20px;
  margin-left: auto;

  background: none;
  border: none;
  cursor: pointer;
}
.menu__burger.menu__burger--touch-down {
  transform: scale(0.85);

  transition-duration: 180ms;
}
.js-focus-visible .menu__burger:focus:not(.focus-visible) {
  outline: none;
}

.menu__burger div {
  position: relative;
  align-items: center;
  width: 26px;
}

.menu__burger div,
.menu__burger div::before,
.menu__burger div::after {
  display: block;
  width: 26px;
  height: 3px;

  border-radius: 2px;
  transform-origin: center center;
}
.menu__burger div::before,
.menu__burger div::after {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;

  transform: translateY(-50%);

  background-color: var(--blue-500);

  content: '';
}
.menu__burger div::before {
  transform: rotate(-45deg);
}
.menu__burger div::after {
  transform: rotate(45deg);
}

.menu__lists {
  display: flex;

  overflow-y: auto;

  align-items: flex-start;

  padding: 40px 0 50px 0;
  height: 100%;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  text-decoration: none;
  cursor: pointer;
}

.menu__lists::before {
  content: '';
  display: block;
  width: 100%;
  height: 60px;

  position: absolute;
  top: 0;

  background-color: var(--white);

  z-index: 1;
}

.menu__lists__item--has-sublist {
  display: block;
}

.menu__lists ul {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  list-style-type: none;

  width: 100%;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.menu__lists__item--has-sublist button svg {
  position: absolute;

  right: -20px;
  top: calc(50% + (32px / 2));
  transform: translateY(-50%);
}

.menu__lists button {
  position: relative;
  width: 100%;
  text-align: left;
  font-family: var(--font-family);

  font-size: 17px;
  font-weight: 600;
  color: var(--primary-500);

  padding-top: 24px;
  letter-spacing: 0.08em;
}

.menu__lists--active > ul {
  display: block;
  padding-left: 20px;
}

.menu__lists__item__sublist {
  padding-left: 20px;
  line-height: 1.3;
}

.menu__lists__item__sublist a {
  display: block;
  padding-top: 16px;
  padding-bottom: 6px;
  font-weight: 600;
  font-size: 14px;

  font-family: var(--font-family);
  color: var(--primary-500);

  text-decoration: none;
}

.menu__lists ul:not(.menu__ctas) > li > a {
  font-family: var(--font-family);
  display: block;
  font-weight: 600;
  padding-top: 20px;

  color: #000;
  font-size: 17px;
  text-decoration: none;
  line-height: 30px;
  width: 100%;

  transition-duration: 120ms;
  transition-property: color;
}
.menu__lists ul {
  padding-top: 20px;
}

.menu__lists ul > li:not(:nth-of-type(1)) > a {
  padding-top: 24px;
}

.menu__lists ul > li > a:hover {
  color: var(--blue-500);
}

/* .menu__locales a {
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  text-transform: uppercase;
}
* + .menu__locales {
  margin-top: 16px;
}
 */

.menu__logo {
  position: relative;
  top: -1px;

  display: flex;
  width: 100%;
  height: 24px;

  align-items: center;

  text-align: center;

  cursor: pointer;
}

.menu__logo img {
  --size: 100%;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
@media (--tablet) {
  .menu__logo img {
    --size: 130px;
  }
}

.menu__ctas {
  margin-top: 24px;
}
