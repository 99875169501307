@custom-media --tablet (max-width: 1024px);
@custom-media --mobile (max-width: 768px);
@custom-media --header-breakpoint (max-width: 900px);

@custom-media --wider-than-desktop (min-width: 1251px);
@custom-media --wider-than-tablet (min-width: 1025px);
@custom-media --wider-than-mobile (min-width: 769px);

:root {
  --font-family-heading-ls: -0.015em;
  --font-family-heading-ls-large: 0.0385em;

  --font-family-heading-ws: -0.05em;

  --header-height: 120px;
  --sup-header-height: 45px;
  --height-flash-info: 52px;
  --height-flash-info-mobile: 64px;
  --page-horizontal-padding: 16px;
  --page-left-padding: calc(var(--page-horizontal-padding));
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: calc(var(--page-horizontal-padding));
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );

  --z-index-popup: 1000;

  --white: #ffffff;

  --primary-500: #1e212c;
  --primary-600: #040814;

  --secondary-500: #f7cc49;

  --green-500: #4a893e;
  --green-500-menu: rgba(74, 137, 62, 0.1);

  --green-600: #3e7a34;
  --violet-500: #59328a;
  --violet-500-menu: rgba(89, 50, 138, 0.1);

  --violet-600: #4c2a7a;
  --raspberry-500: #d3104e;
  --raspberry-500-menu: rgba(211, 16, 78, 0.1);

  --raspberry-600: #b30f46;
  --yellow-500: #ffcb12;
  --yellow-500-menu: rgba(255, 204, 18, 0.1);

  --blue-400: #49caeb;
  --blue-500: #37c4e6;
  --blue-500-menu: rgba(55, 196, 230, 0.1);
  --blue-600: #2faccb;
  --blue-900: #082b35;

  --dark: #141414;

  /* ANCHOR  - Title Size */

  --title-small: 16px;
  --title-medium: 32px;
  --title-large: 48px;
  --title-xlarge: 64px;

  /* ANCHOR  - SubTitle Size */

  --subtitle-small: 12px;
  --subtitle-medium: 24px;
  --subtitle-large: 24px;
  --subtitle-xlarge: 32px;

  --p-size: 16px;
  --title-size: 32px;

  --p-line-height: 1.3;
  --title-line-height: 1.25;
}

@media (--header-breakpoint) {
  :root {
    --sup-header-height: 0px;
  }
}
@media (--mobile) {
  :root {
    /* ANCHOR  - Title Size */

    --title-small: 16px;
    --title-medium: 24px;
    --title-large: 36px;
    --title-xlarge: 50px;
  }
}

html.--js-font-family-heading-loaded {
  --font-family-heading-ls: 0.01em;
  --font-family-heading-ls-large: 0.066em;

  --font-family-heading-ws: 0em;
}
