.mission-video {
  --padding-bottom: 260px;

  position: relative;
  right: 50%;
  left: 50%;

  width: 100vw;
  margin-right: -50vw;
  margin-bottom: var(--margin-bottom);
  margin-left: -50vw;
}

@media (--tablet) {
  .mission-video {
    --padding-bottom: 320px;
  }
}

@media (--mobile) {
  .mission-video {
    --padding-bottom: 0;

    display: flex;
    flex-direction: column-reverse;
  }
}

* + .mission-video {
  margin-top: 120px;
}
@media (--tablet) {
  * + .mission-video {
    margin-top: 80px;
  }
}
@media (--mobile) {
  * + .mission-video {
    margin-top: 50px;
  }
}

.mission-video__wrapper > .max-width {
  height: 100%;
}

.mission-video__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@media (--mobile) {
  .mission-video__wrapper {
    position: static;
  }
  .mission-video.mission-video--started.mission-video--playing
    .mission-video__wrapper {
    pointer-events: auto;
  }
}

.mission-video__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: 100%;
}

@media (--mobile) {
  .mission-video__wrap {
    position: static;

    display: block;
    padding-bottom: 30px;
  }
}

.mission-video__content {
  transition: 400ms opacity, 400ms transform;

  will-change: transform;
}

@media (--mobile) {
  .mission-video__content p {
    color: white !important;
  }
}

.mission-video.mission-video--started.mission-video--playing
  .mission-video__content {
  transform: translateX(-10%);
  opacity: 0;

  pointer-events: none;
}
@media (--mobile) {
  .mission-video.mission-video--started.mission-video--playing
    .mission-video__content {
    transform: none;
    opacity: 1;

    pointer-events: auto;
  }
}

.mission-video__content h2 {
  max-width: 24ch;

  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;

  @media (--tablet) {
    font-size: 20px;
  }

  @media (--mobile) {
    color: var(--dark);
  }
}
.mission-video__content h2 > strong {
  color: var(--primary);
}

.mission-video__content > p {
  max-width: 42.5ch;

  color: var(--background-color);

  font-weight: 500;
  font-size: 16px;
  line-height: 1.8;

  @media (--tablet) {
    font-size: 15px;
  }

  @media (--mobile) {
    color: var(--dark);
  }
}

.mission-video__content > * + p {
  margin-top: 16px;

  @media (--tablet) {
    margin-top: 12px;
  }
}

.mission-video__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

@media (min-width: 1400px) {
  .mission-video__player {
    padding-bottom: 788px;

    transition: 50ms padding-bottom;
  }
  .mission-video.mission-video--started .mission-video__player {
    padding-bottom: 56.25%;
  }
}

.mission-video__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mission-video__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: var(--padding-bottom);
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
}
.mission-video.mission-video--is-mobile.mission-video--started
  .mission-video__player__cover
  > img {
  opacity: 0;

  transition: 200ms opacity;
}

.mission-video__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    radial-gradient(44% 50% at 50% 50%, rgba(4, 8, 20, 0) 14%, #040814 100%);
}

@media (--mobile) {
  .mission-video__player__cover > img {
    bottom: 0;

    height: 100%;
  }

  .mission-video__content {
    color: black;
  }
}

.mission-video__player__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  opacity: 0;
}
.mission-video.mission-video--ready .mission-video__player__wrap {
  opacity: 1;

  transition: 200ms opacity;
}
.mission-video.mission-video--is-mobile .mission-video__player__wrap,
.mission-video.mission-video--ready.mission-video--is-mobile
  .video__player__wrap {
  opacity: 0;

  transition: 300ms opacity;
}
.mission-video.mission-video--is-mobile.mission-video--started
  .mission-video__player__wrap,
.mission-video.mission-video--ready.mission-video--is-mobile.mission-video--started
  .mission-video__player__wrap {
  opacity: 1;
}

.mission-video__player__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  height: 300px;

  transition: 500ms opacity;

  content: '';
  pointer-events: none;
}
.mission-video.mission-video--started.mission-video--playing
  .mission-video__player__wrap::after {
  transition: 2000ms opacity;
}
@media (--mobile) {
  .mission-video__player__wrap::after {
    display: none;
  }
}

.mission-video__player__wrap > * {
  position: relative;

  top: -50%;

  transform: translateY(-6px);
}
.mission-video.mission-video--is-mobile .mission-video__player__wrap > * {
  top: 0;
}

.mission-video__player__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  background-image: linear-gradient(
    180deg,
    rgba(12, 19, 40, 0.7) 0%,
    rgba(12, 19, 40, 0.455) 100%
  );

  transition: 500ms opacity;

  content: '';
  pointer-events: none;
}
.mission-video.mission-video--started.mission-video--playing
  .mission-video__player__gradient {
  opacity: 0;

  transition: 2000ms opacity;
}

@media (--mobile) {
  .mission-video__player__gradient {
    display: none;
  }
}

.mission-video__control {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mission-video.mission-video--is-mobile .mission-video__control {
  display: none;
}

.mission-video__control__play-pause {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: default;
}
.mission-video.mission-video--started.mission-video--playing
  .mission-video_control__play-pause {
  z-index: 3;
}
.mission-video__control__play-pause:focus {
  outline: none;
}

/* PLAY */

.mission-video__play {
  display: block;
  width: 100%;
  height: 100%;

  color: #ffffff;

  transition: 400ms opacity, 400ms transform;

  will-change: opacity, transform;
}
.mission-video.mission-video--started.mission-video--playing
  .mission-video__play {
  transform: scale(0.8);
  opacity: 0;

  transition: 300ms opacity, 300ms transform;

  pointer-events: none;
}

@media (--mobile) {
  .mission-video__play {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;

    width: auto;
    height: 0;
    padding-bottom: 56.25%;

    background-image: linear-gradient(
      180deg,
      rgba(12, 19, 40, 0.7) 0%,
      rgba(12, 19, 40, 0.455) 100%
    );
  }
}

.mission-video__play > button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
}
.mission-video__play > button:focus {
  outline: none;
}
.mission-video.mission-video--started.mission-video--playing
  .mission-video__play
  > button {
  cursor: default;
}

@media (--mobile) {
  .mission-video__play > button {
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 17px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.mission-video__play > button > .mission-video__play__img {
  display: flex;
  width: 87px;
  height: 87px;

  transition: 200ms transform;
}
@media (--mobile) {
  .mission-video__play > button > .mission-video__play__img {
    width: 60px;
    height: 60px;
  }
}

.mission-video__play > button > .mission-video__play__img::before {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
}

.mission-video__play > button > .mission-video__play__img img {
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: 200ms transform;
}
.mission-video__play > button:hover > .mission-video__play__img {
  transform: scale(1.1);

  transition: 200ms transform;
}
.mission-video__play > button:active > .mission-video__play__img img {
  transform: scale(0.95);

  transition: 100ms transform;
}

.mission-video__play > button > .mission-video__play__img + span {
  display: block;
  margin-left: 20px;
  padding-top: 2%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}
@media (--mobile) {
  .mission-video__play > button > .mission-video__play__img + span {
    margin-left: 12px;

    font-size: 20px;
  }
}

.mission-button__text {
  color: #fff;
}

.mission-video__content__button {
  margin-top: 20px;
}
