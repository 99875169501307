@custom-media --pricing (max-width: 900px);

.prices {
  --vertical-padding: 100px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  background-color: var(--white);

  z-index: 1;
}
@media (--pricing) {
  .prices {
    --vertical-padding: 52px;
  }
}

.prices::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: -1;

  background: linear-gradient(180deg, #040814 54.17%, rgba(4, 8, 20, 0.8) 100%);
}

.prices__illustration {
  position: absolute;
  bottom: calc(-1 * var(--vertical-padding));
  right: 0;
  z-index: 2;

  width: auto;
  height: 400px;

  pointer-events: none;
  transform: translateX(32%);
}
@media (max-width: 1300px) {
  .prices__illustration {
    display: none;
  }
}

.title + .prices__content {
  margin-top: 50px;
}

.prices__item__subtitle {
  text-align: center;
}

.prices__item__subtitle + .prices__item__price {
  margin-top: 15px;
}

.prices__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.prices__content--2 {
  grid-template-columns: repeat(2, 1fr);

  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

@media (--pricing) {
  .prices__content {
    grid-template-columns: 1fr;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}

.prices__item {
  --horizontal-padding: 22px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  overflow: hidden;

  background-color: var(--white);
  color: black;

  border-radius: 5px;
}

.prices__item__band {
  --offset: 50px;

  position: absolute;
  top: var(--offset);
  right: var(--offset);

  width: 250px;
  padding: 6px 12px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 18px;
  color: white;
  text-align: center;

  background-color: var(--blue-500);
  transform-origin: center center;
  user-select: none;
  transform: translateX(45%) translateY(-70%) rotate(30deg);
}
@media (--tablet) {
  .prices__item__band {
    --offset: 40px;

    padding: 4px 12px;
    font-size: 17px;

    transform: translateX(50%) translateY(-50%) rotate(45deg);
  }
}

.prices__item__title {
  margin-top: 32px;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  font-size: 24px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;
}
@media (--pricing) {
  .prices__item__title {
    margin-top: 24px;
  }
}

.prices__item__price {
  font-size: 48px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  text-transform: uppercase;

  color: var(--blue-500);
}

.prices__item__price__text {
  color: var(--light-blue, #37c4e6);
  font-size: 16px;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.prices__item__title + .prices__item__price {
  margin-top: 20px;
}

.prices__item__price__text + .prices__item__price__list {
  margin-top: 30px;
}

.prices__item__button {
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 32px;
}

.prices__item__price__list ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 230px;

  gap: 15px;
  padding-bottom: 45px;

  list-style: none;

  font-size: 14px;
  font-family: var(--font-family);
  line-height: 24px;
}

.prices__item__price__list li::before {
  width: 22px;
  height: 13px;
  content: '';
  display: inline-block;
  background-image: url(/icons/bullet_list.svg);
  background-size: 22px 13px;
  background-repeat: no-repeat;

  margin-right: 10px;
}

.prices__container + .prices__list {
  margin-top: 35px;
}

.prices__list ul {
  display: flex;
  flex-direction: column;

  gap: 15px;

  list-style: none;

  font-size: 14px;
  font-family: var(--font-family);
  line-height: 24px;
}

.prices__list li {
  vertical-align: middle;
}

.prices__list li::before {
  position: relative;
  top: -2px;

  width: 30px;
  height: 30px;
  content: '';
  display: inline-block;
  background-image: url(/icons/circle-arrow-right-blue.svg);
  background-size: 30px 30px;
  background-repeat: no-repeat;
  margin-right: 10px;

  vertical-align: inherit;
}

@media (--mobile) {
  .prices__list ul {
    max-width: 400px;

    margin: 0 auto;
  }

  .prices__list li::before {
    transform: scale(0.5);
  }
}
