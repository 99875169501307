.stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stars--filled {
}

.stars--empty {
  opacity: 0.2;
}

.stars--color-violet path {
  fill: var(--violet-500);
}

.stars--color-green path {
  fill: var(--green-500);
}

.stars--color-white path {
  fill: var(--white);
}

.stars--color-black path {
  fill: black;
}

.stars--color-raspberry path {
  fill: var(--raspberry-500);
}

.stars--color-blue path {
  fill: var(--blue-500);
}
