.mission-description__item__icon-violet path {
  fill: var(--violet-500);
}

.mission-description__item__icon-green path {
  fill: var(--green-500);
}

.mission-description__item__icon-raspberry path {
  fill: var(--raspberry-500);
}
