.hour-info {
  padding: 150px 0;
}

@media (--mobile) {
  .hour-info {
    padding: 32px 0;
  }
}

.hour-info__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

@media (--mobile) {
  .hour-info__container {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}

.hour-info__item__block__hour {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  width: 100%;
  height: 100%;

  border-radius: 5px;
  background: rgba(55, 196, 230, 0.2);
}

@media (--mobile) {
  .hour-info__item__block__hour {
    flex-direction: column;
    padding: 32px 0;
    gap: 32px;
  }
}

.hour-info__item__block__hour__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.hour-info__item__text {
  max-width: 45ch;

  color: var(--white, #fff);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.32px;

  @media (--mobile) {
    max-width: 100%;
  }
}

.title + .hour-info__item__text {
  margin-top: 32px;
}

.hour-info__item__block__list {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

@media (--mobile) {
  .hour-info__item__block__list {
    gap: 40px;
  }
}

.hour-info__item__block__list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.hour-info__item__block__list__day {
  color: var(--white, #fff);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.hour-info__item__block__hour__item__title {
  color: var(--white, #fff);
  text-align: center;
  font-family: Anton;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hour-info__item__block__list__hour {
  color: var(--white, #fff);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 200% */
}

.hour-info svg path {
  fill: var(--blue-500);
}
