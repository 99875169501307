.presshero {
  position: relative;

  padding-top: calc(var(--header-height) + var(--sup-header-height) + 64px);
  padding-bottom: 109px;
}
@media (--mobile) {
  .presshero {
    padding: 149px 0 80px 0;
  }
}

.presshero__background img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (--mobile) {
  .presshero__background img {
    object-position: right center;
  }
}
.presshero__background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(
    0deg,
    rgba(4, 8, 20, 0.4) 0%,
    rgba(4, 8, 20, 0.4) 100%
  );
}
@media (--mobile) {
  .presshero__background::after {
    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 33.85%),
      linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 3.22%,
        rgba(1, 3, 7, 0.44) 23.53%,
        rgba(3, 6, 14, 0.6) 47.49%,
        #040814 99.95%
      );
  }
}

.presshero__container {
  display: flex;
  flex-direction: column;
}
@media (--mobile) {
  .presshero__container {
    text-align: center;
    align-items: center;
  }
}

.presshero__text__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 52px;
  line-height: 1.3;
  text-transform: uppercase;
}
@media (--mobile) {
  .presshero__text__title {
    font-size: 32px;
  }
  .presshero__text__title > .title {
    text-align: center;
  }
}

.presshero__text__title + .presshero__text__container {
  margin-top: 40px;
}
@media (--mobile) {
  .presshero__text__title + .presshero__text__container {
    margin-top: 48px;
  }
}

.presshero__text-item__email a {
  color: var(--color-white);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}

.presshero__text__container {
  display: flex;
  flex-direction: row;

  gap: 48px;
}
@media (--mobile) {
  .presshero__text__container {
    flex-direction: column;
    gap: 20px;
  }
}

.presshero__text + .presshero__button {
  margin-top: 48px;
}

.presshero__text__container__item {
  display: flex;
  flex-direction: column;
}

.presshero__text__container__item > .presshero__text__container__item__info {
  display: flex;
  flex-direction: row;
  gap: 3px;
}
