.heading {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
}

.heading__sup-title {
  color: var(--blue-500);

  font-weight: 600;
  line-height: var(--title-line-height);
  text-transform: uppercase;
}
* + .heading__sup-title {
  margin-top: 16px;
}

.heading__title {
  position: relative;
  z-index: 1;
  font-weight: 400;
  font-size: 44px;
  line-height: 1.3;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  text-transform: uppercase;
  color: var(--dark);
}
@media (--mobile) {
  .heading__title {
    font-size: 28px;
  }
}

.heading__sub-title {
  color: var(--blue-500);
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
}

* + .heading__sub-title {
  margin-top: 6px;
}

.heading__sub-title h2 {
  font-weight: normal;
  color: var(--blue-500);
}

@media (--tablet) {
  .heading__sub-title {
    font-size: 20px;
  }
}
@media (--mobile) {
  .heading__sub-title {
    font-size: 16px;
  }
}

* + .heading__title {
  margin-top: 16px;
}
.heading__sup-title + .heading__title {
  margin-top: 12px;
}

.heading__title strong {
  color: var(--blue-500);
}

@media (--mobile) {
  .heading__title strong {
    font-size: 34px;
  }
}
