.quotation {
  --js-scroll-offset-top: 200px;

  position: relative;

  padding-top: 112px;
}
@media (--mobile) {
  .quotation {
    --js-scroll-offset-top: 130px;

    padding-top: 52px;
  }
}

.quotation__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 0;

  max-height: 594px;
}

.quotation__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  object-position: top;
}

.quotation__content__offer__text {
  color: var(--white, #fff);
  font-size: 14px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

* + .quotation__content__offer__text {
  margin-top: 16px;
}

.quotation__container {
  position: relative;
  letter-spacing: 0.01em;
  z-index: 2;
}
@media (--tablet) {
  .quotation__container {
    grid-gap: 52px;
  }
}
@media (--mobile) {
  .quotation__container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.quotation__top__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 52px;
  line-height: 78px;
  text-transform: uppercase;
}
@media (--tablet) {
  .quotation__top__title h2 {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (--mobile) {
  .quotation__top__title h2 {
    font-size: 32px;
    line-height: 48px;
  }
}

.quotation__bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  gap: 16px;
}
@media (--tablet) {
  .quotation__bottom {
    flex-direction: column;
  }
}
@media (--mobile) {
  .quotation__bottom {
    margin-top: 22px;
    flex-direction: column-reverse;
  }
}
