.choose-batman {
  background-color: var(--white);
  color: #000;

  padding: 90px 0;
}
@media (--mobile) {
  .choose-batman {
    padding: 40px 0;
  }
}

.history__dots__item--desactivate {
  display: none !important;
}

.choose-batman__title + .choose-batman__subtitle {
  margin-top: 15px;
}

.choose-batman__subtitle {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (--mobile) {
  .choose-batman__subtitle {
    font-size: 16px;
  }
}

.choose-batman__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 112px;
}
.choose-batman--no-image .choose-batman__content {
  grid-template-columns: 1fr;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
* + .choose-batman__content {
  margin-top: 70px;
}
@media (--tablet) {
  .choose-batman__content {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 50px;
  }
}
@media (--mobile) {
  .choose-batman__content {
    gap: 24px;
  }
  * + .choose-batman__content {
    margin-top: 32px;
  }
}

.choose-batman__content__item {
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
}

.choose-batman__content__item__text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.choose-batman__content__item__text p {
  line-height: normal;
}

.choose-batman__content__item__text ol {
  list-style: none;
  border-left: 2px solid var(--blue-500);
}

.choose-batman__content__item__text ol li {
  position: relative;
  padding-left: 25px;
  line-height: normal;
}

.choose-batman__content__item__text strong {
  color: var(--blue-500);
}

.choose-batman__content__item__text + .choose-batman__content__item__buttons {
  margin-top: 40px;
}

.choose-batman__content__item__buttons {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
.choose-batman--no-image .choose-batman__content__item__buttons {
  justify-content: center;
}
@media (--mobile) {
  .choose-batman__content__item__buttons {
    gap: 16px;
  }
}

.choose-batman__content__item__buttons + .choose-batman__content__item__list {
  margin-top: 65px;
}

.choose-batman__content__item__list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  gap: 100px;
}

.choose-batman__content__item__list__item::after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  background-color: var(--blue-500);
}

@media (--mobile) {
  .choose-batman__content__item__list {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  .choose-batman__content__item__list__item::after {
    display: none;
  }
}

.choose-batman__content__item__image img {
  width: 100%;
  height: auto;

  border-radius: 5px;
}

.choose-batman__content__item__list__item__block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.choose-batman__content__item__list__item__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;
}

.choose-batman__content__item__list__item__text {
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
}
