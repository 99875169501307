* + .find-us {
  margin-top: 40px;
}

.find-us .max-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  padding-top: 47px;
  padding-bottom: 47px;
}

@media (--tablet) {
  .find-us .max-width {
    display: block;
  }
}

/* LEFT */

.find-us__left h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 26px;
}

* + .find-us__left__map {
  margin-top: 32px;
}

.find-us__left__map {
  position: relative;

  min-height: 300px;
  overflow: hidden;
  border-radius: 5px;
}

@media (--tablet) {
  .find-us__left__map {
    grid-row-start: 1;
  }
}
.find-us__left__map__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.find-us__left__map__wrap > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

* .find-us__left__description {
  margin-top: 28px;
}

* + .find-us__left__description p:not(:first-of-type) {
  margin-top: 5px;
}

.find-us__left__description p {
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}

.find-us__left__description a {
  color: #ffcb12;
  font-weight: 400;
}

/* RIGHT */

@media (--tablet) {
  * + .find-us__right {
    margin-top: 40px;
  }
}

.find-us__right {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.find-us__right__content p {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
}

.find-us__right__button .button {
  margin-top: 35px;
}
