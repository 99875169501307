.header__nav__item {
  height: 100%;
  list-style: none;

  position: relative;
}

.header__nav__item a,
.header__nav__item--has-sublist > div > span {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: var(--header-padding-top);
  padding-right: calc(var(--gap) / 2);
  padding-bottom: var(--header-padding-top);
  padding-left: calc(var(--gap) / 2);

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  text-transform: uppercase;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.header__nav__subitem:not(:last-child)::after {
  content: '';
  display: block;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
}

.header__nav__subitem__green:hover {
  background-color: var(--green-500-menu);
}

.header__nav__subitem__green:hover a {
  color: var(--green-500);
}

.header__nav__subitem__violet:hover {
  background-color: var(--violet-500-menu);
}

.header__nav__subitem__violet:hover a {
  color: var(--violet-500);
}

.header__nav__subitem__blue:hover {
  background-color: var(--blue-500-menu);
}

.header__nav__subitem__blue:hover a {
  color: var(--blue-500);
}

.header__nav__subitem__yellow:hover {
  background-color: var(--yellow-500-menu);
}

.header__nav__subitem__yellow:hover a {
  color: var(--yellow-500);
}

.header__nav__subitem__raspberry:hover {
  background-color: var(--raspberry-500-menu);
}

.header__nav__subitem__raspberry:hover a {
  color: var(--raspberry-500);
}

@media (pointer: fine) {
  .header__nav__item a span {
    position: relative;
  }

  .header__nav__item a span::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -4px;

    content: '';
    display: block;
    height: 2px;

    background-color: white;
    transform: scale(0);
  }

  .header__nav__sublist a span::after {
    display: none;
  }

  .js-can-use-transitions .header__nav__item a span::after {
    transition: transform 0.2s ease-in-out;
  }

  .header__nav__item a:hover span::after {
    transform: scale(1);
  }
}

@media (--tablet) {
  .header__nav__item a {
    font-size: 15px;
  }
}

.header__nav__item--hover {
  position: relative;
}

.header__nav__sublist {
  position: absolute;
  top: 80%;
  left: calc(var(--gap) / 2);

  display: 700 none;
  width: auto;

  transform: translateY(-8px);
  perspective: 800px;
  perspective-origin: 50% 0;

  flex-direction: column;
  width: auto;

  background-color: var(--white);

  /* padding: 0 12px; */

  border-radius: 5px;

  list-style: none;
}

.header__nav__sublist__wrap {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.12);
  transform-origin: 50% 0;
}
.header__nav__sublist.header__nav__sublist--hover {
  display: flex;
}

.header__nav__sublist a {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: black;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  text-transform: capitalize;
  white-space: nowrap;

  padding: 8px 16px;
}

.header__nav__item--has-sublist > a > span::before,
.header__nav__item--has-sublist > div > span::after {
  content: '';

  width: 20px;
  height: 20px;

  margin: auto;

  align-items: center;
  justify-content: center;
  text-align: center;

  background-image: url('/icons/chevron.svg');
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: center;
}

.header__nav__item--has-sublist > a > span::before {
  display: block;
  float: right;
}
