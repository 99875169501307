* + .article-link {
  margin-top: 40px;
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.article-link__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.article-link__sup-title {
  display: flex;
  align-items: center;
}

.article-link__sup-title p {
  flex-shrink: 0;

  margin-right: 14px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws-large);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 17px;
  color: var(--primary-600);
}
.article-link__line {
  width: 100%;
  height: 1px;
  background-color: var(--primary-600);
}

.article-link__content {
  position: relative;
  display: flex;
  gap: 16px;

  width: 100%;
  height: 100%;

  padding: 16px 0;

  align-items: center;
}

@media (--mobile) {
  .article-link__content {
    flex-direction: column;
  }
}

.article-link__image {
  width: 150px;
  height: 90px;

  overflow: hidden;
  object-fit: cover;
}
@media (--mobile) {
  .article-link__image {
    width: 100%;
    height: auto;
  }
}

.article-link__link {
  color: var(--primary-600);
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws-large);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 18px;
  text-decoration: none;
}

@media (--mobile) {
  .article-link__link {
    font-size: 16px;
  }
}

.article-link__link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
