.header {
  --header-padding-top: 24px;
  --gap: 32px;
  --logo-width: 183px;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  text-align: center;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

body.theme-white .header {
  background-color: var(--primary-600);
}

@media (--tablet) {
  .header {
    --gap: 16px;
    --logo-width: 150px;
  }
}
@media (--header-breakpoint) {
  .header {
    --header-padding-top: 24px;
  }
}
@media (--mobile) {
  .header {
    --logo-width: 140px;
  }
}

.header__gradient {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(5, 18, 34, 0.85),
    cubic-bezier(0.6, 0.12, 0.74, 0.89),
    rgba(5, 18, 34, 0)
  );
  pointer-events: none;
  opacity: 0;
  transform: translateY(-100px);
}

.header__over-menu {
  position: relative;

  z-index: 1;

  height: var(--sup-header-height);
  background: linear-gradient(90deg, #123b6b 0%, #0a0a0a 100%);
}

.header__over-menu .max-width {
  height: 100%;
}

.header__over-menu__list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  list-style: none;
  height: 100%;

  gap: 32px;
}
@media (--header-breakpoint) {
  .header__over-menu__list {
    display: none;
  }
}

.header__over-menu__list__item {
  position: relative;
}

.header__over-menu__list__item:not(
    .header__over-menu__list__item-adress
  ):after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.header__over-menu__list__item:hover::after {
  transform: scaleX(1);
}

.header__over-menu__list__item-separator {
  width: 2px;
  height: 13px;
  background-color: #fff;
}

.header__over-menu__list__item span {
  color: #fff;
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.26px;
  text-transform: uppercase;
}

.header__over-menu__list__item > a {
  text-decoration: none;
}

.header__over-menu__list__item-adress span {
  color: #fff;
  font-family: var(--font-family);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.26px;
  text-transform: initial;
}
.header__over-menu__list__item-adress:hover span {
  text-decoration: underline;
}

.js-can-use-transitions .header__gradient {
  transition-duration: 400ms;
  transition-property: opacity, transform;
}

.header--scrolled .header__gradient {
  opacity: 1;
  transform: translateY(var(--sup-header-height));
}
@media (--header-breakpoint) {
  .header__gradient {
    height: 100px;
  }
}

.js-can-use-transitions .header > .max-width {
  transition-duration: 400ms;
  transition-property: all;
}

.header__grid {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr calc(var(--logo-width) + var(--gap)) 1fr;
}
@media (--header-breakpoint) {
  .header__grid {
    grid-template-columns: 1fr;
  }
}

.header__logo {
  display: block;
  width: calc(var(--logo-width) + var(--gap));
  padding-top: var(--header-padding-top);
  padding-right: calc(var(--gap) / 2);
  padding-bottom: var(--header-padding-top);
  padding-left: calc(var(--gap) / 2);
  margin-left: auto;
  margin-right: auto;
}

.header__logo img {
  width: 100%;
  height: auto;
  margin: auto;

  object-fit: contain;
}
.header__logo__light {
  display: block;
}

.header__part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (--header-breakpoint) {
  .header__part {
    display: none;
  }
}

.header__part--left {
  justify-content: flex-end;
}

.header__nav {
  display: flex;
  align-items: center;
}

* + .header__nav {
  margin-left: 28px;
}

.header__ctas {
  padding-top: 4px;

  list-style: none;
}
* + .header__ctas {
  margin-left: calc(var(--gap) / 2);
}

.header__ctas .button {
  min-width: 99px;
}

.header__mobile-cta {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: none;
  align-items: center;
  justify-content: center;
  padding-left: var(--page-left-padding);
}
@media (--header-breakpoint) {
  .header__mobile-cta {
    display: flex;
  }
}
@media (max-width: 360px) {
  .header__mobile-cta {
    display: none;
  }
}

.header__mobile-cta .button {
  --height: 35px;
  --horizontal-padding: 10px;
  --vertical-padding: 0;

  position: relative;
  top: -1px;
}
.header__mobile-cta .button__right-icon {
  padding-left: 0;
  border: none;
}
.header__mobile-cta * + .button__right-icon {
  margin-left: 9px;
}
.header__mobile-cta .button__right-icon::before {
  display: none;
}
.header__mobile-cta .button__right-icon svg {
  --size: 12px;

  position: relative;
  top: 1px;
}

@media (--mobile) {
  .menu a[href='#devis'] {
    display: none;
  }
}

.header__burger {
  --size: 78px;

  position: absolute;
  top: -1px;
  right: 0;

  display: none;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin-left: auto;

  background: none;
  border: none;
  cursor: pointer;
}
.header__burger.header__burger--touch-down {
  transform: scale(0.85);
}
.js-can-use-transitions .header__burger.header__burger--touch-down {
  transition-duration: 180ms;
}
@media (--header-breakpoint) {
  .header__burger {
    display: flex;
  }
}
.js-focus-visible .header__burger:focus:not(.focus-visible) {
  outline: none;
}

.header__burger div {
  position: relative;
  width: 30px;

  margin-top: -4px;
}

.header__burger div,
.header__burger div::before,
.header__burger div::after {
  display: block;
  height: 2.5px;

  background-color: var(--blue-500);
  border-radius: 2px;
}

.header__burger div::before,
.header__burger div::after {
  width: 20px;

  position: absolute;
  top: -9px;
  right: 0;
  bottom: auto;

  content: '';
}
.header__burger div::after {
  top: auto;
  bottom: -9px;
}
