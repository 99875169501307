.article-content-bottom {
  color: var(--primary-600);
  margin-top: 40px;
}

.article-content-bottom .max-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}

@media (--mobile) {
  .article-content-bottom .max-width {
    display: block;
  }

  * + .article-content-bottom__right {
    margin-top: 45px;
  }
}

.article-content-bottom h3 {
  font-weight: 600;
  font-size: 24px;
}

.article-content-bottom * + p {
  margin-top: 30px;
}

.article-content-bottom h3 + p {
  margin-top: 32px;
}

.article-content-bottom p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.article-content-bottom a {
  color: var(--blue-500);
  text-decoration: none;
}

.article-content-bottom a:hover {
  text-decoration: underline;
}

* + .article-content-top__right__share {
  margin-top: 40px;
}

.article-content-top__right__share {
  background-color: #ebf9fc;
  padding: 16px;
  display: flex;
}

.article-content-top__right__share p {
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.article-content-top__right__share .footer__social {
  margin: 0;
}

.article-content-top__right__share * + .footer__social {
  margin-left: 10px;
}

.article-content-top__right__share .footer__social a {
  padding: 0;
}

.article-content-top__right__share .footer__social * + li {
  margin-left: 20px;
}

.article-content-top__right__share .footer__social img {
  filter: brightness(0) saturate(0%);
}
