.contact {
  padding-top: calc(var(--header-height) + var(--sup-header-height) + 64px);
  position: relative;
}

.js-can-use-transitions .contact {
  transition-duration: 400ms;
  transition-property: all;
}

@media (--mobile) {
  .contact {
    padding-top: var(--header-height);
  }
}

.main--flash-info .contact {
  padding-top: calc(var(--header-height) + var(--height-flash-info) + 40px);
}
@media (--mobile) {
  .main--flash-info .contact {
    padding-top: calc(var(--header-height) + var(--height-flash-info-mobile));
  }
}

.contact__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, rgba(4, 8, 20, 0) 17.51%, #040814 100%);
  z-index: 1;
  max-height: 594px;
}

.contact__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;
  z-index: 0;

  max-height: 594px;
}

.contact__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  object-position: top;
}

.contact__container {
  position: relative;
  letter-spacing: 0.01em;
  z-index: 2;
}
@media (--tablet) {
  .contact__container {
    grid-gap: 52px;
  }
}
@media (--mobile) {
  .contact__container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
.contact__top__title h1,
.contact__top__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 52px;
  line-height: 78px;
  text-transform: uppercase;
}
@media (--tablet) {
  body .contact__top__title h1,
  body .contact__top__title h2 {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (--mobile) {
  body .contact__top__title h1,
  body .contact__top__title h2 {
    font-size: 32px;
    line-height: 48px;
  }
}

.contact__bottom {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  gap: 16px;
}

@media (--tablet) {
  .contact__bottom {
    flex-direction: column;
  }
}

@media (--mobile) {
  .contact__bottom {
    margin-top: 22px;
  }
}
