.not-found {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  padding-top: 100px;
}

.not-found__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  text-align: center;
}

.not-found__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
@media (--mobile) {
  .not-found__title {
    font-size: 64px;
  }
}

.not-found__title + .not-found__icon {
  margin-top: 25px;
}
@media (--mobile) {
  .not-found__title + .not-found__icon {
    margin-top: 18px;
  }
}

.not-found__text {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.not-found__icon + .not-found__text {
  margin-top: 25px;
}
@media (--mobile) {
  .not-found__icon + .not-found__text {
    margin-top: 12px;
  }
}

.not-found__text + .not-found__link {
  margin-top: 15px;
}

@media (--mobile) {
  .not-found__text {
    font-size: 20px;
  }
}

.not-found__link a {
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.32px;
  text-decoration-line: underline;

  color: var(--white);
}
@media (--mobile) {
  .not-found__link a {
    font-size: 14px;
  }
}
