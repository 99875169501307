.flash-info {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: var(--height-flash-info);
  padding: 6px 0 6px 32px;
  overflow: hidden;

  color: black;

  background-color: var(--secondary-500);

  pointer-events: auto;
  transform: translateY(0);
}
.js-can-use-transitions .flash-info {
  transition-duration: 400ms;
  transition-property: all;
}
@media (--mobile) {
  .flash-info {
    height: var(--height-flash-info-mobile);
  }
}

.flash-info:not(.flash-info--show) {
  pointer-events: none;
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info:not(.flash-info--show) {
    transform: translateY(-64px);
  }
}

.flash-info:not(.flash-info--show) + .max-width {
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info:not(.flash-info--show) + .max-width {
    transform: translateY(-64px);
  }
}

.flash-info--scrolled {
  pointer-events: none;
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info--scrolled {
    transform: translateY(-64px);
  }
}

.flash-info--scrolled + .max-width {
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info--scrolled + .max-width {
    transform: translateY(-64px);
  }
}

.flash-info__inner {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 32px 1fr;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}
@media (--mobile) {
  .flash-info__inner {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.flash-info__inner > img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}
@media (--mobile) {
  .flash-info__inner > img {
    display: none;
  }
}

.flash-info__content {
  align-items: center;

  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--mobile) {
  .flash-info__content {
    font-size: 15px;
  }
}

.flash-info__content > :first-child::before {
  font-weight: bold;
  font-style: normal;

  content: 'Flash info : ';
}

.flash-info > button {
  display: none;
  height: auto;
  padding-right: 32px;
  padding-left: 32px;

  align-items: center;

  cursor: pointer;
}
.js-focus-visible .flash-info > button:focus:not(.focus-visible) {
  outline: none;
}
@media (--mobile) {
  .flash-info > button {
    height: auto;
    padding-right: 16px;
    padding-left: 6px;
  }
}

.flash-info > button > img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.flash-info__content a {
  color: black;
}
