.prolonged-experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;

  position: relative;

  background-color: var(--white);

  color: black;

  z-index: 1;
}

.prolonged-experience__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translate(0, -10%);

  object-fit: cover;
  object-position: center;

  overflow: hidden;

  background: linear-gradient(
    180deg,
    rgba(55, 196, 230, 0) 0%,
    rgba(55, 196, 230, 0.1) 100%
  );

  z-index: -1;
}

@media (--mobile) {
  .prolonged-experience__background {
    transform: translate(0, 0);
  }
}

.prolonged-experience__title {
  color: #000;

  text-align: center;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.prolonged-experience__title + .prolonged-experience__menu {
  margin-top: 50px;
}

.prolonged-experience__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.prolonged-experience__menu__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  gap: 15px;
}

@media (--mobile) {
  .prolonged-experience__menu__list__container {
    padding-top: 150px;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .prolonged-experience__menu__list {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .prolonged-experience__menu__list__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;

    z-index: 0;
  }

  .prolonged-experience__menu__list__item__active {
    opacity: 1;

    z-index: 1;
  }
}

.prolonged-experience__menu__list__item__button {
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000;
  background-color: transparent;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 8px 16px;
  min-width: 250px;
}

.prolonged-experience__menu__list__item__back__button,
.prolonged-experience__menu__list__item__next__button {
  display: none;
}

@media (--mobile) {
  .prolonged-experience__menu__list__item__next__button {
    transform: rotate(180deg);
  }

  .prolonged-experience__menu__list__item__back__button,
  .prolonged-experience__menu__list__item__next__button {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (--mobile) {
  .prolonged-experience__menu__list__item__button {
    font-size: 16px;
  }
}

.prolonged-experience__menu__list__item__button:hover {
  color: var(--blue-500);
}

.prolonged-experience__menu__list__item__active
  .prolonged-experience__menu__list__item__button {
  border: 2px solid var(--blue-500);
  color: var(--blue-500);
  border-radius: 4px;
}

.prolonged-experience__list {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  opacity: 0;
  user-select: none;
}

.prolonged-experience__item {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

@media (--mobile) {
  .prolonged-experience__item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.prolonged-experience__option__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.prolonged-experience__item-title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.prolonged-experience__item-text {
  width: 433px;

  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (--mobile) {
  .prolonged-experience__item-text {
    width: 100%;
  }
}

.prolonged-experience__item-text + .prolonged-experience__item-icon__text {
  margin-top: 30px;
}

.prolonged-experience__item-icon__text {
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.prolonged-experience__item-image__img {
  width: 430px;
  height: 430px;
  object-fit: cover;

  border-radius: 5px;
  box-shadow: 0px 0px 26px 5px rgba(0, 0, 0, 0.15);
}

@media (--mobile) {
  .prolonged-experience__item-image__img {
    width: 100%;
    height: 100%;
  }
}

.prolonged-experience__item-image__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.prolonged-experience__menu + .prolonged-experience__list__absolute {
  margin-top: 120px;
}

@media (--mobile) {
  .prolonged-experience__menu + .prolonged-experience__list__absolute {
    margin-top: 40px;
  }
}

.prolonged-experience__list__absolute {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: 100%;
}

.prolonged-experience__item__absolute {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  gap: 80px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
}
@media (--mobile) {
  .prolonged-experience__item__absolute {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;

    gap: 25px;
  }
}

.prolonged-experience__item__absolute.active {
  opacity: 1;
}
