.presse-sup-doc {
  margin-top: 135px;
}
@media (--mobile) {
  .presse-sup-doc {
    margin-top: 80px;
  }
}

.presse-sup-doc__container {
  display: flex;
  flex-direction: row;
  gap: 56px;
}

@media (--mobile) {
  .presse-sup-doc__title > .title > * {
    font-size: 19px;
  }
}

.presse-sup-doc__title + .presse-sup-doc__container {
  margin-top: 44px;
}

.presse-sup-doc__item {
  position: relative;

  display: flex;
  flex-direction: column;
}

.presse-sup-doc__item a {
  color: var(--white);
  text-decoration: none;
}
.presse-sup-doc__item a:hover {
  text-decoration: underline;
}
.presse-sup-doc__item a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.presse-sup-doc__item__block {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
