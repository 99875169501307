.hero-article {
  position: relative;
  padding-top: calc(var(--header-height) + var(--sup-header-height) + 52px);
  padding-bottom: 170px;
}
@media (--tablet) {
  .hero-article {
    padding-top: 180px;
  }
}
@media (--mobile) {
  .hero-article {
    padding-top: 150px;
  }
}
@media (max-width: 450px) {
  .hero-article {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

.hero-article__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url('/backgrounds/article-top-background.webp');
  background-repeat: repeat-x;
  background-size: 1400px;
}
@media (--tablet) {
  .hero-article__background {
    background-size: 1300px;
    height: 110%;
  }
}
@media (--mobile) {
  .hero-article__background {
    background-size: 900px;
  }
}
@media (max-width: 450px) {
  .hero-article__background {
    background-size: 700px;
  }
}

.hero-article__image {
  display: block;
  margin: -15px auto;
  width: 100%;
  height: auto;
}
