* + .article-image-banner {
  margin-top: 40px;
}

.article-image-banner .max-width {
  position: relative;
  height: 300px;
}

.article-image-banner__background {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
