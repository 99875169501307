* + .all-articles {
  padding-top: 56px;
}
@media (--mobile) {
  * + .all-articles {
    padding-top: 32px;
  }
}
.all-articles {
  padding-top: 60px;
}

.all-articles__item__date p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.01em;

  color: #37c4e6;
}

.all-articles__item__date + .all-articles__item__title {
  margin-top: 8px;
}
.all-articles__container__title h2 {
  text-align: left;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);

  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;

  color: black;
}

.all-articles__title {
  max-width: 516px;
}

.all-articles__title--no-title {
  margin: 0 auto;
}

.all-articles__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 40px;

  color: black;
}

.all-articles__title > img {
  width: 100%;
  height: 100%;
}

.all-articles__background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.all-articles__background > img {
  width: 100%;
  height: 100%;
}

.all-articles--is-last .all-articles__background > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

* + .all-articles__list {
  margin-top: 32px;
}

.all-articles__list {
  width: 100%;
  height: 100%;

  display: grid;
  grid-gap: 16px;
  row-gap: 48px;
  grid-template-columns: repeat(3, 1fr);
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .all-articles__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }
}
@media (--mobile) {
  .all-articles__list {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}

* + .all-articles__container {
  margin-top: 32px;
}

.all-articles__item--first {
  width: 100%;
  height: 100%;
  grid-column: 1 / 3;
  grid-row: 1;
  padding-right: 16px;
}

@media (--tablet) {
  .all-articles__item--first {
    grid-column: 1 / 3;
    grid-row: 1;
    padding-right: 0;
  }
}
@media (--mobile) {
  .all-articles__item--first {
    grid-column: 1 / 2;
    grid-row: 1;
  }
}

.all-articles__item {
  position: relative;
  width: 100%;
  height: 100%;
}

* + .all-articles__item__content {
  padding-top: 25px;
}
@media (--tablet) {
  * + .all-articles__item__content {
    padding-top: 20px;
  }
}
@media (--mobile) {
  * + .all-articles__item__content {
    padding-top: 16px;
  }
}

.all-articles__item__image {
  width: 100%;

  height: 213px;
  overflow: hidden;
}
@media (--mobile) {
  .all-articles__item__image {
    height: 180px;
  }
}

.all-articles__item--first .all-articles__item__image {
  height: 428px;
}

.all-articles__item__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.all-articles__item--first .all-articles__item__title a,
.all-articles__item--first .all-articles__item__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark);
}

.all-articles__item__title a,
.all-articles__item__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--dark);
}
.all-articles__item__title a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 3;
}

.all-articles__item:hover
  .all-articles__item__content
  .all-articles__item__title
  a {
  text-decoration: underline;
}

.all-articles__item__content > h3 > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.all-articles__item--first .all-articles__item__description {
  max-width: 546px;
}

* + .all-articles__item__description {
  padding-top: 10px;
}

@media (--tablet) {
  * + .all-articles__item__description {
    padding-top: 14px;
  }
}

@media (--mobile) {
  * + .all-articles__item__description {
    padding-top: 12px;
  }
}

.all-articles__item__description > p {
  display: -webkit-box;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: var(--dark);
}

.all-articles__item--first .all-articles__item__description > p {
  display: -webkit-box;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: var(--dark);
}
