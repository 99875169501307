.faq__image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.faq__image--1,
.faq__image--2 {
  overflow: hidden;
  height: 393px;

  border-radius: 5px;
}

@media (--tablet) {
  .faq__image--1,
  .faq__image--2 {
    width: 100%;
    height: 100%;
    max-height: 257px;
  }
}

.faq__image--2 {
  margin-top: 0;
}

.faq__image--1 + .faq__image--2 {
  margin-top: 121px;
}

@media (--tablet) {
  .faq__image--1 + .faq__image--2 {
    margin-top: 0;
  }
}

.faq__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
