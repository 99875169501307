.map-container {
  width: 100%;
  height: 100%;
}
.marker {
  background-image: url('/icons/location-marker.svg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}
.mapboxgl-popup-content {
  text-align: center;
  font-family: var(--font-family);
  color: var(--dark);
}
.mapboxgl-popup-close-button {
  font-size: 1.5em;
  width: 20px;
  height: 20px;
}
