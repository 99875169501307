.head-contact + .gallery {
  margin-top: 32px;
}

.gallery {
  background-color: var(--white);
}
@media (--mobile) {
  .gallery {
    background-color: var(--black);
  }
}
.gallery__container-desktop {
  height: 689px;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    "image-1 image-1 image-1 image-1 image-1 image-2 image-2 image-2 image-4 image-4 image-4 image-4"
    "image-1 image-1 image-1 image-1 image-1 image-3 image-3 image-3 image-4 image-4 image-4 image-4"
    "image-5 image-5 image-5 image-5 image-6 image-6 image-6 image-6 image-7 image-7 image-7 image-7";
  grid-gap: 16px;
}

.gallery__item {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.gallery__container-mobile {
  display: none;
}
@media (--mobile) {
  .gallery__container-mobile {
    display: block;
  }
  .gallery__container-desktop {
    display: none;
  }
}

.gallery__container-mobile__grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.gallery__container-mobile__grid > div {
  height: 170px;
}

.gallery__container-mobile__item-halfwidth {
  display: flex;
  flex-direction: row;

  gap: 10px;
}

.gallery__container-mobile__item-halfwidth .mobile-halfwidth {
  width: 50%;
}

.gallery__item:nth-of-type(1) {
  grid-area: image-1;
}
.gallery__item:nth-of-type(2) {
  grid-area: image-2;
}
.gallery__item:nth-of-type(3) {
  grid-area: image-3;
}
.gallery__item:nth-of-type(4) {
  grid-area: image-4;
}
.gallery__item:nth-of-type(5) {
  grid-area: image-5;
}
.gallery__item:nth-of-type(6) {
  grid-area: image-6;
}
.gallery__item:nth-of-type(7) {
  grid-area: image-7;
}

.gallery__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--primary-600);
  opacity: 0;

  transition: 400ms opacity;

  content: "";
  pointer-events: none;
}
.gallery__item:hover::before {
  opacity: 0.7;
}

.gallery__item > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  color: white;
  font-weight: 600;

  text-align: center;
  text-decoration: none;

  opacity: 0;

  transition: 400ms opacity;
  z-index: 1;
}

.gallery__item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
.gallery__item:hover > a {
  opacity: 1;
}

.gallery__item > a > img {
  display: block;
  width: 32px;
  height: 32px;
  margin-bottom: 14px;
}

.gallery__show-more-button {
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  color: white;
}
.gallery__show-more-button::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  background-image: url("/icons/arrow-see-more.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
