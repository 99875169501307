* + .article-selection {
  margin-top: 112px;
}
@media (--mobile) {
  * + .article-selection {
    margin-top: 60px;
  }
  .image-text + .article-selection {
    margin-top: 52px;
  }
}

.article-selection {
  --top-height: 200px;
  --background-size: 1800px;

  position: relative;
  z-index: 1;

  padding-top: 93px;
  padding-bottom: 200px;
}
@media (--tablet) {
  .article-selection {
    --background-size: 1200px;
  }
  .article-selection:not(.article-selection--is-last) {
    padding-bottom: 252px;

    background-size: 2350px;
  }
}
@media (--mobile) {
  .article-selection {
    --background-size: 600px;
  }
  .article-selection:not(.article-selection--is-last) {
    padding-top: 72px;
    padding-bottom: 100px;

    background-size: 3320px;
    background: none;
  }
}
.article-selection-no-background {
  padding-top: unset;
  padding-bottom: unset;
}

.article-selection--is-last {
  z-index: 4;

  height: unset;
  padding-top: 160px;
  padding-bottom: 90px;
}

.article-selection__title {
  max-width: 516px;
  max-height: 107px;
}

.article-selection__title--no-title {
  margin: 0 auto;
}

.article-selection__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 40px;

  color: black;
}

.article-selection__title > img {
  width: 100%;
  height: 100%;
  user-select: none;
}

* + .article-selection__list {
  margin-top: 32px;
}

.article-selection__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  width: 100%;

  letter-spacing: 0.01em;
}
@media (--tablet) {
  .article-selection__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .article-selection__list {
    grid-template-columns: 1fr;
  }
}

* + .article-selection__container {
  margin-top: 32px;
}

.article-selection__item {
  position: relative;
}

.article-selection__item__content {
  max-width: 273px;
}
@media (--tablet) {
  .article-selection__item__content {
    max-width: 100%;
  }
}

* + .article-selection__item__content {
  padding-top: 25px;
}
@media (--tablet) {
  * + .article-selection__item__content {
    padding-top: 20px;
  }
}
@media (--mobile) {
  * + .article-selection__item__content {
    padding-top: 16px;
  }
}

.all-articles__container__image {
  text-align: center;
}

.all-articles__container__image img {
  width: 50%;
}

.article-selection__item__image {
  width: 100%;
  height: 182px;
  border-radius: 5px;
  overflow: hidden;
}
@media (--mobile) {
  .article-selection__item__image {
    height: 120px;
  }
}

.article-selection__item__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-selection__item__title a,
.article-selection__item__title h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-decoration: none;
  color: var(--dark);
}

.article-selection__item__title-no-background a,
.article-selection__item__title-no-background h2 {
  color: var(--white);
}

.article-selection__item__title a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 3;
}

.article-selection__item:hover
  .article-selection__item__button
  a
  .button__content
  .button__text,
.article-selection__item:hover
  .article-selection__item__button
  a
  .button__content
  .button__right-icon
  svg
  * {
  text-decoration: underline;
}

.article-selection__item__content > h3 > a {
  color: var(--dark);
  text-decoration: none;
}
.article-selection__item__content > h3 > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

* + .article-selection__item__description {
  padding-top: 10px;
}

@media (--tablet) {
  * + .article-selection__item__description {
    padding-top: 14px;
  }
}

@media (--mobile) {
  * + .article-selection__item__description {
    padding-top: 12px;
  }
}

.article-selection__item__description > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: var(--dark);
}

.article-selection__item__description-no-background > p {
  color: var(--white);
}

* + .article-selection__item__button {
  padding-top: 8px;
}

.article-selection__button {
  --icon-size: 10px;

  text-align: center;
  position: absolute;
  bottom: 150px;
  left: 0;
  right: 0;
}

@media (--mobile) {
  .article-selection__button {
    bottom: 120px;
  }
}

.article-selection__button a {
  color: #141414;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  position: relative;
}

.article-selection__button a svg {
  position: absolute;
  height: var(--icon-size);
  width: var(--icon-size);
  bottom: -12px;
  left: calc(50% - (var(--icon-size) / 2));
  transition: transform 0.3s ease-in-out;
}

.article-selection__button a:hover svg {
  transform: translateY(4px);
}

* + .article-selection__bottom {
  margin-top: 24px;
}

.article-selection__bottom {
  text-align: center;
}

.article-selection__bottom a {
  position: relative;
  color: var(--dark);
  text-decoration: none;
}

.article-selection__bottom svg {
  transition: transform 0.3s ease-in-out;
}

.article-selection__bottom a:hover svg {
  transform: translateY(5px);
}
