.contact__details {
  margin-bottom: auto;
  width: 100%;
  max-width: 369px;

  padding: 36px 30px;

  background: rgba(55, 196, 230, 0.4);
  backdrop-filter: blur(6px);
}

@media (--tablet) {
  .contact__details {
    max-width: 100%;
    height: 100%;

    padding: 36px 24px;
  }
}

@media (--mobile) {
  * + .contact__details {
    margin-top: 32px;
    padding: 16px;
  }
}

.contact__content__container__link .button__text {
  text-align: left;
}
.contact__content__container__description {
  height: 100%;
  word-break: break-word;
}

* + .contact__content__container__title {
  margin-top: 20px;
}

.contact__content__container__description a {
  color: var(--white);
  text-decoration: underline;
}

.contact__content__container__description a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.contact__content__container__description * + a {
  display: block;
  margin-top: 8px;
}
