.popup__overlay,
.popup__overlay__close {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-popup);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: var(--visual-viewport-height);
}
.popup__overlay {
  padding: 0 16px;

  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;

  &[data-entering] {
    animation: modal-fade 200ms;
  }

  &[data-exiting] {
    animation: modal-fade 150ms reverse ease-in;
  }
}

.popup__overlay__close {
  pointer-events: auto;
  cursor: default;
}

.popup__modal {
  --gap: 24px;

  position: relative;
  z-index: var(--z-index-popup);

  max-width: 600px;
  height: fit-content;
  padding: 48px;

  text-align: center;
  color: var(--dark);

  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  pointer-events: auto;

  &[data-entering] {
    animation: modal-zoom 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
@media (--tablet) {
  .popup__modal {
    padding: 32px;
  }
}
@media (--mobile) {
  .popup__modal {
    --gap: 18px;

    padding: 24px 16px;
  }
}

.popup__modal .react-aria-Dialog {
  outline: none;
}

.popup__close {
  --size: 20px;

  position: absolute;
  top: 0;
  right: 0;

  padding: 24px 32px;
}
@media (--mobile) {
  .popup__close {
    padding: 16px;
  }
}

.popup__close svg {
  width: var(--size);
  height: var(--size);

  transition: 200ms transform;
}
.popup__close:hover svg {
  transform: scale(1.1);
}
.popup__close:active svg {
  transform: scale(1);
}

.popup__heading {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-size: 24px;
  line-height: 1.4;
}
@media (--tablet) {
  .popup__heading {
    font-size: 22px;
  }
}
@media (--mobile) {
  .popup__heading {
    max-width: 24ch;
    margin-left: auto;
    margin-right: auto;
  }
}

.popup__description {
  max-width: 46ch;
  margin-left: auto;
  margin-right: auto;

  line-height: 1.5;
}
* + .popup__description {
  margin-top: var(--gap);
}

.popup__cta {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
* + .popup__cta {
  margin-top: var(--gap);
}
@media (--mobile) {
  .popup__cta {
    gap: 12px;
  }
}

@keyframes modal-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes modal-zoom {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
