* + .faq {
  margin-top: 112px;
}
@media (--tablet) {
  * + .faq {
    margin-top: 75px;
  }
}
@media (--mobile) {
  * + .faq {
    margin-top: 65px;
  }
}

.hero--no-slice + .faq {
  margin-top: 50px;
}

.faq + .faq {
  margin-top: 80px;
}
@media (--mobile) {
  .faq + .faq {
    margin-top: 32px;
  }
}

/* .faq__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  letter-spacing: 0.01em;
  grid-gap: 56px;
}
@media (--tablet) {
  .faq__container {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
} */

.faq__wrap__text {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
}

.faq__content__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  width: 100%;

  overflow-y: hidden;
}
@media (--mobile) {
  .faq__content__container {
    margin-top: 24px;
  }
}

/* .faq__content__container--open {
  height: auto;
  max-height: 700px;
  padding-bottom: 16px;

  opacity: 1;
  transition: all 0.4s ease;
} */

.faq__title h2 {
  position: relative;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.3;
  text-transform: uppercase;
  z-index: 1;
}
@media (--mobile) {
  .faq__title h2 {
    font-size: 24px;
  }
}

.faq__title h2 strong {
  color: var(--blue-500);
}

.faq__subtitle p {
  line-height: 1.6;
}

.faq__subtitle p a {
  color: var(--blue-500);
  text-decoration: none;
}
.faq__subtitle p a:hover {
  text-decoration: underline;
}
