.hero-missions {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: calc(var(--header-height) + var(--sup-header-height));
  padding-bottom: 50px;
  box-sizing: border-box;

  color: #fff;
  text-align: center;
}
@media (--mobile) {
  .hero-missions {
    padding-top: 92px;
  }
}

.hero-missions__image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, rgba(4, 8, 20, 0) 35.51%, #040814 100%),
    radial-gradient(
      33.26% 66.23% at 50% 50%,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    );
}

.hero-missions__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.hero-missions__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.hero-missions__keywords {
  padding: 5px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 21px;
  text-transform: uppercase;

  margin-top: -5px;
  z-index: -2;
}

.hero-missions__keywords--theme-green {
  background-color: var(--green-500);
}

.hero-missions__keywords--theme-violet {
  background-color: var(--violet-500);
}

.hero-missions__keywords--theme-raspberry {
  margin-top: 5px;
  background-color: var(--raspberry-500);
}

.hero-missions__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  width: 571px;
}

.hero-missions__logo {
  width: 370px;
  height: 240px;
  object-fit: contain;
}
@media (--mobile) {
  .hero-missions__logo {
    width: calc(370px * 0.7);
    height: calc(240px * 0.7);
  }
}

.image-text__paragraph {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
}
@media (--mobile) {
  .image-text__paragraph {
    font-size: 17px;
  }
}

.hero-missions__button {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);

  --height: 50px;
  --min-height: var(--height);
  --horizontal-padding: 24px;
  --vertical-padding: 15px;
  --color: var(--white);

  position: relative;

  display: inline-block;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  overflow: hidden;
  margin-top: 20px;

  text-decoration: none;

  border: 1px solid var(--white);
  cursor: pointer;
  user-select: none;
  transition-duration: 400ms;
  transition-property: transform;
}

.hero-missions__button:hover {
  transform: scale(1.1);
}

.hero-missions__button {
  transition: transform border 200ms ease-in-out;
}

.hero-missions__button .button__content .button__text {
  transition: color 200ms ease-out;
}

.hero-missions__button--green:hover {
  border: 1px solid var(--green-500);
}

.hero-missions__button--raspberry:hover {
  border: 1px solid var(--raspberry-500);
}

.hero-missions__button--green:hover .button__content .button__text {
  color: var(--green-500);
}

.hero-missions__button--raspberry:hover .button__content .button__text {
  color: var(--raspberry-500);
}

@media (--mobile) {
  .hero-missions__content {
    width: 100%;
    padding: 0 var(--page-right-padding) 0 var(--page-left-padding);
  }

  .hero-missions__button {
    --height: 46px;
  }

  .hero-missions__keywords {
    font-size: 19px;
  }
}
