.article-content-single {
  color: var(--primary-600);
  width: 100%;
  max-width: 1000px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .article-content-single {
    width: 100%;
  }
}

.article-content-single p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.article-content-single * + p,
.article-content-single * + ul,
.article-content-single * + ol {
  margin-top: 24px;
}

.article-content-single ul,
.article-content-single ol {
  padding-left: 20px;
}

.article-content-single li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}

.article-content-single li + li {
  margin-top: 20px;
}

.article-content-single a {
  color: var(--blue-500);
  text-decoration: none;
}

.article-content-single a:hover {
  text-decoration: underline;
}

.article-content-single img {
  width: 100%;
}

.article-content-single--little-images.article-content-single img {
  width: 100%;

  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.article-content-single * + h2 {
  margin-top: 32px;
}

.article-content-single h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 28px;
  line-height: 1.4;
  text-transform: uppercase;
}
@media (--mobile) {
  .article-content-single h2 {
    font-size: 24px;
  }
}

.article-content-single h2:before {
  display: block;
  content: '';
  height: 7px;
  width: 30%;
  background-color: var(--blue-500);
  margin-bottom: 16px;
}

.article-content-single h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
}
.article-content-single * + h3 {
  margin-top: 24px;
}
