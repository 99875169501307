.spacer-slice {
  --height: 0;
  --height-mobile: var(--height);
  --background-color: transparent;
  --background-color-mobile: var(--background-color);

  width: 100%;
  height: var(--height, 0);

  background-color: var(--background-color);
}
@media (--mobile) {
  .spacer-slice {
    height: var(--height-mobile, var(--height, 0));

    background-color: var(--background-color-mobile, var(--background-color));
  }
}
