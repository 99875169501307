.iframe {
  padding: 60px 0 70px 0;
  background-color: var(--white);
}
* + .iframe {
  margin-top: 85px;
}
.prices + .iframe,
.article-content-single + .iframe {
  margin-top: 0;
}
@media (--mobile) {
  .iframe {
    padding: 20px 0;
  }
  * + .iframe {
    margin-top: 0;
  }
}

.iframe--width-small .max-width {
  max-width: 800px;
}

.iframe__title {
  text-align: center;
  font-size: 32px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  text-transform: uppercase;
  color: black;
}
@media (--mobile) {
  .iframe__title {
    margin-top: 32px;

    font-size: 26px;
  }
}

.iframe__customers {
  display: flex;
  column-gap: 40px;
  row-gap: 8px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
* + .iframe__customers {
  margin-top: 32px;
}
@media (--mobile) {
  .iframe__customers {
    column-gap: 24px;
  }
}

.iframe__customers img {
  width: auto;
  height: 60px;
}
@media (--mobile) {
  .iframe__customers img {
    height: 50px;
  }
}

.iframe__embed {
  position: relative;
  z-index: 1;
}
* + .iframe__embed {
  margin-top: 32px;
}
.iframe__customers + .iframe__embed {
  margin-top: 64px;
}
@media (--mobile) {
  * + .iframe__embed {
    margin-top: 24px;
  }
  .iframe__customers + .iframe__embed {
    margin-top: 24px;
  }
}
