.why-us {
  padding: 100px 0;
  background-color: var(--white);
}
.choose-batman + .why-us {
  padding-top: 0;
}
@media (--mobile) {
  .why-us {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .choose-batman + .why-us {
    padding-top: 0;
  }
}

.why-us__content__text {
  text-align: center;

  color: black;
}

.why-us__content__text__title {
  text-align: center;
  font-size: 32px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  text-transform: uppercase;
}
@media (--mobile) {
  .why-us__content__text__title {
    font-size: 26px;
  }
}

.why-us__content__text__subtitle {
  font-size: 16px;
  font-family: var(--font-family);
  line-height: 1.5;
}

.why-us__content__text__title + .why-us__content__text__subtitle {
  margin-top: 15px;
}

.why-us__list {
  --gap: 24px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);

  padding-top: 75px;
  margin: 0 auto;
}
@media (--mobile) {
  .why-us__list {
    --gap: 20px;

    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: var(--gap);
    padding-top: 20px;
  }
}

.why-us__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: black;
  padding-left: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) / 2);
}

.why-us__item__icon {
  --size: 72px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);

  background-color: #ebf9fc;
  border-radius: 50%;
}
@media (--mobile) {
  .why-us__item__icon {
    --size: 64px;
  }
}

.why-us__item__icon svg {
  --size: 34px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
@media (--mobile) {
  .why-us__item__icon svg {
    --size: 28px;
  }
}

* + .why-us__item__title {
  margin-top: 20px;

  position: relative;
}

.why-us__item__title span {
  font-weight: bold;
}

.why-us__item__text {
  display: none;

  font-size: 15px;
}
* + .why-us__item__text {
  margin-top: 16px;
}
@media (--mobile) {
  .why-us__item__text {
    display: block;
  }
}

.why-us__item p {
  line-height: 1.4;
}

.why-us__item__description__block {
  --triangle-left: 0%;

  position: relative;

  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: 58px;

  background-color: #ebf9fc;
  color: black;
}
@media (--mobile) {
  .why-us__item__description__block {
    display: none;
  }
}

.why-us__item__description__block::before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: var(--triangle-left);

  display: inline-block;
  width: 0;
  height: 0;
  border-right: 16px solid transparent;
  border-bottom: 24px solid #ebf9fc;
  border-left: 16px solid transparent;

  transform: translateX(-16px);
  transition: 200ms left;
}

.why-us__item__description {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  width: 100%;
  padding: 32px 46px;

  color: #040814;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
}
.why-us__item__description--active {
  opacity: 1;
  pointer-events: auto;
}
