.compact-convenient {
  --vertical-margin: 112px;

  position: relative;
  margin-top: var(--vertical-margin);
  overflow: hidden;
}
@media (--tablet) {
  .compact-convenient {
    --vertical-margin: 64px;
  }
}
@media (--mobile) {
  .compact-convenient {
    --vertical-margin: 52px;
  }
}

.compact-convenient__container {
  position: relative;

  display: grid;
  grid-gap: 57px;
  grid-template-columns: repeat(2, 1fr);
}

@media (--tablet) {
  .compact-convenient__container {
    grid-template-columns: 1fr;
    grid-gap: 26px;
  }
}

.compact-convenient__list__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  row-gap: 32px;

  padding-top: 24px;
  list-style: none;
}
@media (--tablet) {
  .compact-convenient__list__container {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.compact-convenient__list__item {
  align-items: center;
}

.compact-convenient__list__item p + p {
  margin-top: 0;
}

.compact-convenient__title + .compact-convenient__content {
  margin-top: 40px;
}

.compact-convenient__content .compact-convenient__title h2 {
  position: relative;
  z-index: 1;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;

  max-width: 30ch;
}

.compact-convenient__content .compact-convenient__title h2 strong {
  position: relative;

  display: inline-block;
  color: #090c15;
  transform: rotate(-2.53deg);
  margin: 4px 0;
}

.compact-convenient__content .compact-convenient__title h2 strong::before {
  position: absolute;
  top: 0;
  right: -6px;
  bottom: 0;
  left: -6px;
  z-index: -1;

  content: '';

  background-color: var(--secondary-500);
}

@media (--tablet) {
  .compact-convenient__content h2 {
    font-size: 24px;
    line-height: 32px;

    max-width: 100%;
  }
}

@media (--mobile) {
  .compact-convenient__content h2 {
    font-size: 20px;
    line-height: 28px;
  }
}

.compact-convenient__list__item h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: var(--secondary-500);
}

@media (--tablet) {
  .compact-convenient__list__item h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

* + .compact-convenient__road,
* + .compact-convenient__shipping {
  margin-top: 10px;
}

* + .compact-convenient__shipping--no-icon {
  margin-top: 4px;
}

.compact-convenient__road,
.compact-convenient__shipping {
  display: flex;
  align-items: flex-start;
}
.compact-convenient__shipping__item__icon > *,
.compact-convenient__road__item__icon > * {
  width: 19px;
  height: 19px;
}

.compact-convenient__road__item__text,
.compact-convenient__shipping__item__text {
  margin-left: 16px;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
}

.compact-convenient__shipping--no-icon
  .compact-convenient__shipping__item__text {
  margin-left: 35px;
}

.compact-convenient__shipping__item__text {
  width: 100%;
}

.compact-convenient__road__item__text strong,
.compact-convenient__shipping__item__text strong {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.compact-convenient__shipping__item__text__split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

@media (--tablet) {
  .compact-convenient__shipping__item__text__split {
    width: 250px;
  }
}

.compact-convenient__shipping__item__text__split p:last-of-type {
  font-weight: 700;
}

.compact-convenient__map {
  position: relative;

  height: 100%;
  min-height: 360px;
  overflow: hidden;
  border-radius: 5px;
}

@media (--tablet) {
  .compact-convenient__map {
    grid-row-start: 1;
  }
}
.compact-convenient__map__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.compact-convenient__map__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
