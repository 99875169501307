/**
 * Top part
 */

.article-selection__top__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  height: var(--top-height);
}
@media (--wider-than-mobile) {
  .article-selection__top__background {
    background-image: url('/backgrounds/article-bottom-background.webp');
    background-size: var(--background-size);
    background-repeat: repeat-x;
    background-position: top center;
  }
}

.article-selection__top__background img {
  position: absolute;
  left: 50%;
  top: 0;

  width: 100%;
  min-width: 600px;
  height: auto;

  transform: translateX(-50%);
}
@media (--wider-than-mobile) {
  .article-selection__top__background img {
    display: none;
  }
}

/**
 * Middle part
 */

.article-selection__middle__background {
  position: absolute;
  top: var(--top-height);
  right: 0;
  bottom: var(--top-height);
  left: 0;
  z-index: -1;

  background-image: url('/backgrounds/article-infinite-bottom-background.webp');
  background-repeat: repeat;
  background-size: var(--background-size);
  background-position: center center;
  background-color: #eae0c7;
}
.article-selection--is-last .article-selection__middle__background {
  bottom: 0;
}
@media (--mobile) {
  .article-selection__middle__background {
    background-image: url('/backgrounds/article-infinite-bottom-background-mobile.webp');
  }
}

/**
 * Bottom part
 */

.article-selection__bottom__background {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  height: var(--top-height);
}
@media (--wider-than-mobile) {
  .article-selection__bottom__background {
    background-image: url('/backgrounds/article-top-background.webp');
    background-size: var(--background-size);
    background-repeat: repeat-x;
    background-position: bottom center;
  }
}

.article-selection--is-last .article-selection__bottom__background {
  display: none;
}

.article-selection__bottom__background img {
  position: absolute;
  left: 50%;
  bottom: 0;

  width: 100%;
  min-width: 600px;
  height: auto;
  transform: translateX(-50%);
}
@media (--wider-than-mobile) {
  .article-selection__bottom__background img {
    display: none;
  }
}
