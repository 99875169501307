.contact__form {
  --gap: 16px;

  position: relative;

  width: 100%;
}
@media (max-width: 900px) {
  .contact__form {
    --gap: 10px;
  }
}
@media (--mobile) {
  .contact__form {
    justify-content: flex-start;
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }
  * + .contact__form {
    margin-top: 32px;
  }
}

@keyframes contact__form-input-flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}

.contact__form--state-loading .contact__form__input,
.contact__form--state-loading .contact__form__textarea {
  cursor: default;
  animation-name: contact__form-input-flash;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.contact__form > form {
  width: 100%;
  transition: opacity 200ms;
}
.contact__form--state-success > form {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.contact__form > form > .button {
  --horizontal-padding: 32px;
}
.contact__form > form > * + .button {
  margin-top: var(--gap);
}

.contact__form__split {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(2, 1fr);
}
* + .contact__form__split  {
  margin-top: var(--gap);
}
.contact__form__split + .contact__form__split {
  margin-top: var(--gap);
}
@media (max-width: 500px) {
  .contact__form__split {
    grid-template-columns: 1fr;
  }
}

.contact__form__split + .contact__form__input-container {
  margin-top: var(--gap);
}

.contact__form__input-container + .contact__form__input-container {
  margin-top: var(--gap);
}

.contact__form__split > .contact__form__input-container {
  margin-top: 0;
}

.contact__form__input-container,
.contact__form__input-container-textarea {
  position: relative;
}

.contact__form__input,
.contact__form__textarea {
  min-width: 100px;
  width: 100%;
  overflow: hidden;

  padding: 11px;

  border: 1px solid #ababab;

  color: black;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;

  background-color: var(--white);
  letter-spacing: 0.01em;

  -webkit-appearance: none;
}

.contact__form__input::placeholder,
.contact__form__textarea::placeholder {
  color: #a1a1a1;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
}

.contact__form__input:focus,
.contact__form__textarea:focus {
  outline: none;
}

@media (--tablet) {
  .contact__form__input,
  .contact__form__textarea {
    padding-right: 14px;
    padding-left: 14px;
  }
}

.contact__form__textarea {
  width: 100%;
  min-height: 324px;
  resize: vertical;
}

.contact__form__split + .contact__form__input-container-textarea {
  margin-top: var(--gap);
}
.contact__form__input-container + .contact__form__input-container-textarea {
  margin-top: var(--gap);
}

.contact__form .button {
  max-width: 276px;
}

.contact__form__cta {
  display: flex;
  align-items: center;
}
* + .contact__form__cta {
  margin-top: var(--gap);
}

.contact__form__cta .button {
  flex-shrink: 0;
}

.contact__form__cta__error {
  flex: 1;
  display: flex;
  align-items: center;
}

* + .contact__form__cta__error {
  margin-left: 24px;
}
@media (--tablet) {
  * + .contact__form__cta__error {
    margin-left: 12px;
  }
}

.contact__form__cta__error img {
  --size: 17px;

  width: var(--size);
  height: var(--size);
  user-select: none;
}

.contact__form__cta__error p {
  color: var(--white);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .contact__form__cta__error p {
    font-size: 15px;
  }
}

.contact__form__cta__error * + p {
  margin-left: 10px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contact__form__success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  background-color: rgba(255, 255, 255, 0.1);
  animation-name: fade-in;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: 1;
  max-height: 307px;
}

.contact__form__success img {
  --size: 22px;

  width: var(--size);
  height: var(--size);
  user-select: none;
}

.contact__form__success h3 {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
.contact__form__success * + h3 {
  margin-top: 16px;
}

.contact__form__success p {
  max-width: 44ch;
  margin-left: auto;
  margin-right: auto;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
}
.contact__form__success * + p {
  margin-top: 6px;
}
