.quotation__form {
  --gap: 16px;

  position: relative;

  width: 100%;
}
@media (max-width: 900px) {
  .quotation__form {
    --gap: 10px;
  }
}
@media (--mobile) {
  .quotation__form {
    justify-content: flex-start;
    grid-row-start: auto;
    grid-row-end: auto;
    grid-column-start: auto;
    grid-column-end: auto;
  }
  * + .quotation__form {
    margin-top: 32px;
  }
}

@keyframes quotation__form-input-flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}

.quotation__form--state-loading .quotation__form__input,
.quotation__form--state-loading .quotation__form__textarea {
  cursor: default;
  animation-name: quotation__form-input-flash;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.quotation__from__wrap {
  width: 100%;
  max-width: 700px;
  transition: opacity 200ms;
}
.quotation__from__wrap--grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 64px;
  max-width: 100%;
}
@media (--tablet) {
  .quotation__from__wrap--grid {
    grid-gap: 22px;
  }
}
@media (--mobile) {
  .quotation__from__wrap--grid {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}

.quotation__form--state-success > form {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.quotation__form > form > .button {
  --horizontal-padding: 32px;
}
.quotation__form > form > * + .button {
  margin-top: var(--gap);
}

.quotation__form__split {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: repeat(2, 1fr);
}
* + .quotation__form__split  {
  margin-top: var(--gap);
}
.quotation__form__split + .quotation__form__split {
  margin-top: var(--gap);
}

.quotation__form__input-container + * {
  margin-top: var(--gap);
}

@media (max-width: 500px) {
  .quotation__form__split {
    grid-template-columns: 1fr;
  }
}

.quotation__form__split + .quotation__form__input-container {
  margin-top: var(--gap);
}

.quotation__form__input-container + .quotation__form__input-container {
  margin-top: var(--gap);
}

.quotation__form__split > .quotation__form__input-container {
  margin-top: 0;
}

.quotation__form__input-container,
.quotation__form__input-container-textarea {
  position: relative;
}

.quotation__form__input,
.quotation__form__textarea {
  min-width: 100px;
  width: 100%;
  overflow: hidden;

  padding: 11px;

  border-radius: 2px;

  color: white;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;

  background-color: #222632;
  letter-spacing: 0.01em;

  -webkit-appearance: none;
}
.quotation__form__input--high {
  height: 65px;
}

.quotation__form__input::placeholder,
.quotation__form__textarea::placeholder {
  color: #afafaf;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
}

.quotation__form__input:focus,
.quotation__form__textarea:focus {
  outline: none;
}

@media (--tablet) {
  .quotation__form__input,
  .quotation__form__textarea {
    padding-right: 14px;
    padding-left: 14px;
  }
}

.quotation__form__textarea {
  width: 100%;
  min-height: 190px;
  resize: vertical;
}

.quotation__form__split + .quotation__form__input-container-textarea {
  margin-top: var(--gap);
}
.quotation__form__input-container + .quotation__form__input-container-textarea {
  margin-top: var(--gap);
}

.quotation__form .button {
  max-width: 276px;
}

.quotation__form__cta {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
* + .quotation__form__cta {
  margin-top: 32px;
}

.quotation__form__cta .button {
  flex-shrink: 0;
}

.quotation__form__cta__error {
  flex: 1;
  display: flex;
  align-items: center;
}

* + .quotation__form__cta__error {
  margin-top: 16px;
}

.quotation__form__cta__error img {
  --size: 17px;

  width: var(--size);
  height: var(--size);
  user-select: none;
}

.quotation__form__cta__error p {
  color: var(--white);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .quotation__form__cta__error p {
    font-size: 15px;
  }
}

.quotation__form__cta__error * + p {
  margin-left: 10px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.quotation__form__success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;

  background-color: rgba(255, 255, 255, 0.1);
  animation-name: fade-in;
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: 1;
  max-height: 307px;
}

.quotation__form__success img {
  --size: 22px;

  width: var(--size);
  height: var(--size);
  user-select: none;
}

.quotation__form__success h3 {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
}
.quotation__form__success * + h3 {
  margin-top: 16px;
}

.quotation__form__success p {
  max-width: 44ch;
  margin-left: auto;
  margin-right: auto;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
}
.quotation__form__success * + p {
  margin-top: 6px;
}

input[type='date'],
input[type='time'] {
  font-size: 14px;
  -webkit-text-fill-color: #afafaf; /* Override iOS / Android font color change */
  -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  color: #afafaf; /* Override IE font color change */
}

input[type='date']::-webkit-search-cancel-button,
input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  color: white !important;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0;
  width: 1.5rem;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: white !important;
  opacity: 1;
  background-image: url(/icons/calendar.svg);
  width: 14px;
  height: 18px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: 0.5rem;
}
input[type='date']::-webkit-calendar-picker-indicator:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
}

input[type='date']::before,
input[type='time']::before {
  content: attr(placeholder) ' : ';
  white-space: pre;
  font-size: 14px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input[type='date']:focus::before,
input[type='time']:focus::before {
  /* content: '' !important; */
}

* + .quotation__content__container__offer {
  margin-top: 24px;
}

* + .select {
  margin-top: 12px;
}

select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 40px 0 20px;
  margin: 0;
  width: 100%;
  height: 100%;
  color: #afafaf;
  font-size: 16px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
  text-overflow: ellipsis;
}
select::-ms-expand {
  display: none;
}
.select {
  --select-border: transparent;
  --select-focus: blue;
  --select-arrow: var(--blue-500);

  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  min-width: 300px;
  max-width: 300px;
  min-height: 50px;
  max-height: 50px;
  border: 1px solid var(--select-border);
  border-radius: 2px;
  padding: 13px 20px;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
.quotation__form__select-selected {
  color: #000;
}

@media (--mobile) {
  .select {
    min-width: 100%;
    max-width: 100%;
  }
}

.select:not(.select--multiple)::after {
  content: '';
  justify-self: end;
  width: 0.8em;
  height: 0.5em;
  background-color: var(--select-arrow);
  clip-path: polygon(80% 0%, 15% 0%, 50% 80%);
}
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.quotation__form__checkbox {
  user-select: none;

  cursor: pointer;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 10px;
}
* + .quotation__form__checkbox {
  margin-top: 16px;
}

input[type='checkbox'] {
  cursor: pointer;
  --form-background: var(--white);
  --form-control-color: var(--blue-500);
  --form-control-disabled: #959495;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
