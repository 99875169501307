.image-text {
  position: relative;
  width: 100%;
}

.image-text--bg-white {
  --vertical-padding: 80px;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  background-color: var(--white);
}
@media (--tablet) {
  .image-text--bg-white {
    --vertical-padding: 0;
  }
}

* + .image-text {
  margin-top: 112px;
}
@media (--mobile) {
  * + .image-text {
    margin-top: 48px;
  }
  .mission-gallery + .image-text {
    margin-top: 32px;
  }
}

* + .image-text--bg-white {
  margin-top: 0;
}

.image-text--first {
  margin-top: 0;
  padding-top: 140px;
}
@media (--tablet) {
  .image-text--first {
    padding-top: 90px;
  }

  * + .image-text--bg-white {
    padding-top: 50px;
  }
}
@media (--mobile) {
  .image-text--first {
    padding-top: 50px;
  }
}

.image-text--no-image-cover .image-text__container {
  max-width: 1200px;
  margin: 0 auto;
}

.image-text__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;

  background: linear-gradient(
    90deg,
    rgba(4, 8, 20, 1) 50%,
    rgba(4, 8, 20, 0) 60%
  );
}
.image-text--reverse .image-text__gradient {
  background: linear-gradient(
    270deg,
    rgba(4, 8, 20, 1) 50%,
    rgba(4, 8, 20, 0) 60%
  );
}
.image-text--has-background .image-text__gradient {
  display: none;
}

@media (--mobile) {
  .image-text__gradient {
    display: none;
  }
}

.image-text__container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.image-text--no-image-cover .image-text__container {
  grid-template-columns: 0.85fr 1fr;
}

.image-text--no-image-cover.image-text--reverse .image-text__container {
  grid-template-columns: 0.85fr 1fr;
}

@media (--mobile) {
  .image-text__container {
    grid-template-columns: 1fr !important;
  }
}

.image-text__image {
  position: relative;

  height: 100%;
  overflow: hidden;
}

@media (--mobile) {
  .image-text__image {
    display: none;
  }
}

.image-text__image__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  z-index: 4;

  background: radial-gradient(
    83.79% 51.13% at 50% 48.87%,
    rgba(4, 8, 20, 0) 0%,
    #040814 100%
  );
}

.image-text__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.image-text--no-image-gradient .image-text__image__gradient {
  display: none;
}

.image-text__image__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.image-text__image__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.image-text--no-image-cover.image-text--bg-white
  .image-text__gradient
  + .image-text__image__wrap
  img {
  object-fit: none;
}

.image-text--no-image-cover.image-text--no-image-gradient
  .image-text__image__wrap
  img {
  object-fit: contain;
}

.image-text__content {
  position: relative;
  width: 100%;

  height: 100%;

  z-index: 1;
  max-width: 564px;
  justify-self: end;
}
.image-text--reverse .image-text__content {
  padding-left: 0;
}

.image-text--reverse .image-text__content {
  order: 1;
  justify-self: start;
}

.image-text--no-image-cover .image-text__content {
  padding-top: 60px;
  padding-bottom: 30px;
}
.image-text--bg-white .image-text__content {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (--tablet) {
  .image-text__content {
    max-width: 100%;
  }
  .image-text--bg-white .image-text__content {
    padding-top: 0;
  }
}

.image-text__content h2 {
  position: relative;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 32px;
  line-height: 1.25;
  z-index: 1;

  max-width: 25ch;
}
@media (--mobile) {
  .image-text__content h2 {
    font-size: 28px;
  }
}

.image-text__content h2 strong {
  position: relative;

  display: inline-block;
  color: #000000;
  transform: rotate(-1.79deg) translateX(-20px);
  margin: 6px 0;
}

.image-text__content h2 strong::before {
  position: absolute;
  top: 0;
  right: -6px;
  bottom: 0;
  left: -6px;
  z-index: -1;

  content: '';

  background-color: var(--blue-500);
}

.image-text--no-image-cover .title::before {
  display: none;
}

.image-text--no-image-cover .title h1 {
  font-size: 52px;
}
@media (--mobile) {
  .image-text--no-image-cover .title h1 {
    font-size: 32px;
  }
}

.image-text .title--border-top::before {
  content: '';
  display: block;
  width: 100px;
  height: 7px;
  margin-bottom: 16px;
  background-color: var(--color);
}

* + .image-text__paragraph {
  margin-top: 16px;
}

.image-text__content .image-text__paragraph {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.9);
}

.image-text__content .image-text__title + .image-text__paragraph {
  margin-top: 16px;
}

.image-text__paragraph p + p {
  margin-top: 16px;
}

.image-text__paragraph p + pre {
  margin-top: 16px;
}

.image-text__paragraph pre + p {
  margin-top: 16px;
}

.image-text__paragraph pre {
  white-space: pre-wrap;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;

  border-left: 2px solid var(--secondary-500);

  padding-left: 16px;
}

.image-text__paragraph a {
  color: var(--blue-500);

  transition: all 200ms ease-in-out;
}

.image-text__paragraph a:hover {
  color: var(--white);
  transition: all 200ms ease-in-out;
}

.image-text__paragraph + .button {
  margin-top: 32px;
}

* + .image-text__buttons {
  margin-top: 40px;
}
@media (--tablet) {
  * + .image-text__buttons {
    margin-top: 32px;
  }
}

.image-text__buttons {
  --gap: 16px;
}

.image-text__buttons > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: calc(var(--gap) / 2 * -1);
}

.image-text__buttons > * > * {
  margin: calc(var(--gap) / 2);
}

.mission-gallery + .image-text {
  padding-top: 80px;
}

.image-text--bg-white .image-text__content {
  background-color: var(--white);
  color: black;
}

.image-text--bg-white .image-text__content .image-text__paragraph {
  color: black;
}

.image-text--bg-white .image-text__gradient {
  background-color: white;
  position: static;
}
