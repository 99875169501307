.hero-title {
  position: relative;
}

.hero-title::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  content: '';

  background: linear-gradient(0deg, rgba(4, 8, 20, 0.33), rgba(4, 8, 20, 0.33)),
    linear-gradient(180deg, rgba(4, 8, 20, 0) 20.74%, #040814 100%),
    radial-gradient(
      32.12% 44.05% at 50% 50%,
      rgba(4, 8, 20, 0.9) 0%,
      rgba(4, 8, 20, 0) 100%
    );
}

.hero-title--position-left::before {
  background: unset;
}

.hero-title .max-width {
  --max-width: 660px;

  position: relative;
  z-index: 2;

  padding: calc(var(--header-height) + var(--sup-header-height) + 30px)
    var(--page-right-padding) 80px var(--page-left-padding);

  text-align: center;
}

.hero-title--position-left .max-width {
  --max-width: 1136px;

  display: flex;
  align-items: center;
  gap: 8px;

  text-align: left;
}
@media (--tablet) {
  .hero-title .max-width {
    padding-top: 160px;
    padding-bottom: 50px;
  }
}
@media (--mobile) {
  .hero-title .max-width {
    padding-top: 120px;
  }
  .hero-title--has-video .max-width {
    flex-direction: column;
    gap: 24px;
  }
}

.hero-title--position-left .hero-title__content {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}

.hero-title__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.hero-title--position-left .hero-title__background-container {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 40%;
}

.hero-title--full-size .hero-title__background-container {
  left: 0;
}

.hero-title--position-left .hero-title__background__gradient {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(
    52.19% 52.19% at 50% 50%,
    rgba(0, 0, 0, 0) 42.32%,
    rgba(2, 4, 10, 0.52) 76.02%,
    #040814 100%
  );
  z-index: 2;
}

.hero-title--full-size .hero-title__background-overlay {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  z-index: 2;
}

.hero-title__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 45px;
  line-height: 1.3;
  text-transform: uppercase;
}
.hero-title--position-left .hero-title__title {
  max-width: 25ch;
}
.hero-title--has-video .hero-title__title {
  font-size: 42px;
}
@media (--tablet) {
  .hero-title__title {
    font-size: 42px;
  }
  .hero-title--has-video .hero-title__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .hero-title__title {
    margin-left: auto;
    margin-right: auto;

    font-size: 35px;
    text-align: center;
  }
  .hero-title--has-video .hero-title__title {
    font-size: 28px;
  }
}

* + .hero-title__description {
  margin-top: 28px;
}

.hero-title__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.hero-title--position-left .hero-title__description {
  max-width: 50ch;
}
.hero-title__description a {
  color: inherit;
}
@media (--mobile) {
  .hero-title__description {
    margin-left: auto;
    margin-right: auto;

    text-align: center;
  }
}

.hero-title__buttons {
  --gap: 16px;
}

* + .hero-title__buttons {
  margin-top: 40px;
}
@media (--tablet) {
  * + .hero-title__buttons {
    margin-top: 32px;
  }
}

.hero-title__buttons > * {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(var(--gap) / 2 * -1);
}

.hero-title--position-left .hero-title__buttons > * {
  justify-content: start;
}

.hero-title__buttons > * > * {
  margin: calc(var(--gap) / 2);
}

@media (--mobile) {
  .hero-title--position-left .hero-title__buttons > * {
    justify-content: center;
  }
  .choose-batman__content__item__buttons {
    flex-direction: column;
    align-items: center;
  }
}

.hero-title__google-reviews {
  position: relative;
  width: 170px;
}
* + .hero-title__google-reviews {
  margin-top: 32px;
}
@media (--mobile) {
  .hero-title__google-reviews {
    margin-left: auto;
    margin-right: auto;
  }
}

.hero-title__google-reviews img {
  width: 100%;
  height: auto;
}

.hero-title__google-reviews p {
  color: white;
}
.hero-title__google-reviews * + p {
  margin-top: 8px;
  font-size: 13px;
  text-align: center;
}

.hero-title__google-reviews p a {
  color: inherit;
  text-decoration: none;
}
.hero-title__google-reviews p a::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-title--position-left .hero-title__video {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
}
@media (--mobile) {
  .hero-title__video {
    width: 100%;
  }
}

.hero-title__video-overflow {
  width: 130%;
}
@media (max-width: 1600px) {
  .hero-title__video-overflow {
    width: 115%;
  }
}
@media (max-width: 1400px) {
  .hero-title__video-overflow {
    width: 100%;
  }
}

.hero-title__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 56%;
}

.hero-title__player__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
  border-radius: 16px;
  background: black;
}

.hero-title__player__wrap__react-player {
  transform: translateY(-25%);
}

.hero-title__player__embed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-title__control {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.hero-title--video-started .hero-title__control {
  display: none;
}

.hero-title__control img {
  --size: 100px;

  width: var(--size);
  height: var(--size);

  transition: 120ms transform ease-in-out;
}
.hero-title__control:hover img {
  transform: scale(1.2);
}
.hero-title__control:active img {
  transform: scale(1);
}
