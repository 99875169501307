.mission-description {
  position: relative;
}

* + .mission-description {
  margin-top: 112px;
}

.image-text.image-text--bg-white + .mission-description {
  margin-top: 0;
}

.mission-description__wrap {
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mission-description__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 105px 0;
  max-width: 760px;
  padding: 0 var(--page-right-padding) 0 var(--page-left-padding);
}

.mission-description__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.mission-description__background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    114.05% 100.25% at 50% 50%,
    #040814 0%,
    rgba(4, 8, 20, 0) 100%
  );
}

.mission-description__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.mission-description__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mission-description__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  gap: 32px;
}

.mission-description__title {
  font-size: 24px;
  font-weight: bold;
}

@media (--tablet) {
  .mission-description__title .title-title h2 {
    font-weight: 400;
  }
}

.mission-description__article {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  text-align: center;
  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.9);
}

.mission-description__divider {
  width: 104px;
  height: 2px;
  background-color: #fff;
  border: none;
}

.mission-description__divider--violet {
  background-color: var(--violet-500);
}

.mission-description__divider--green {
  background-color: var(--green-500);
}

.mission-description__divider--blue {
  background-color: var(--blue-500);
}

.mission-description__divider--raspberry {
  background-color: var(--raspberry-500);
}

.mission-description__paragraph {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;

  max-width: 90%;
  overflow: hidden;

  background-color: #fff;
  color: #000;
  transform: translate(-50%, 50%);
  border-radius: 5px;
}
@media (--mobile) {
  .mission-description__paragraph {
    position: static;

    width: 100%;
    max-width: 100%;

    transform: none;

    background-color: transparent;
    color: #fff;
  }
  * + .mission-description__paragraph {
    margin-top: 24px;
  }
}

.mission-description__paragraph__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 52px;
  gap: 8px;
}
@media (--mobile) {
  .mission-description__paragraph__container {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.mission-description__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 40px;
}

.mission-description__item {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 100%;
  gap: 16px;

  text-align: center;
}
@media (--mobile) {
  .mission-description__item {
    justify-content: flex-start;
    gap: 12px;
  }
}

.mission-description__item svg {
  width: 45px;
  height: 45px;
  object-position: center;
}

.mission-description__item__content__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mission-description__item__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.mission-description__item__description {
  line-height: 1.5;
}

.mission-description__item:not(:last-child):after {
  position: absolute;
  right: -20px;
  content: '';
  width: 1px;
  height: 50px;
  background-color: black;
  opacity: 0.5;
}

.mission-description__item-violet:not(:last-child):after {
  background-color: var(--violet-500);
}

.mission-description__item-raspberry:not(:last-child):after {
  background-color: var(--raspberry-500);
}

.mission-description__item-green:not(:last-child):after {
  background-color: var(--green-500);
}

.mission-description__item-blue:not(:last-child):after {
  background-color: var(--blue-500);
}

@media (--mobile) {
  .mission-description__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    gap: 16px;
  }

  .mission-description__item {
    text-align: center;
    width: 100%;
  }

  .mission-description__item:after {
    display: none;
  }
}
