.journey__timeline-background__left {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  width: 60%;
  overflow: hidden;

  opacity: 0.6;
}
@media (--tablet) {
  .journey__timeline-background__left {
    left: 0;

    width: unset;
  }
}
@media (--mobile) {
  .journey__timeline-background__left {
    opacity: 0.3;
  }
}

.journey__timeline-background__left::before {
  --offset: 512px;

  position: absolute;
  top: 0;
  left: calc(-1 * (var(--offset)) / 2.5);
  z-index: 6;

  content: '';
  width: var(--offset);
  height: 100%;

  background-image: linear-gradient(90deg, #071526 50%, rgba(0, 0, 0, 0));
}
@media (--tablet) {
  .journey__timeline-background__left::before {
    display: none;
  }
}

.journey__timeline-background__left__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  background-image: url('/illustrations/journey/timeline/background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.journey__timeline-background__left__image {
  width: auto;
  height: auto;
}

.journey__timeline-background__left__image,
.journey__timeline-background__left__card {
  position: absolute;

  border-radius: 10px;
  transform: scale(0.5);
  opacity: 0;
}

.journey__timeline-background__left__card {
  top: 60%;
  left: 10%;

  width: 200px;
  height: 350px;

  background-color: #2c494a;
}

#journey__timeline-background__left__image__0 {
  top: 45%;
  left: 50%;
  z-index: 6;

  height: 446px;
}

#journey__timeline-background__left__image__1 {
  top: 0%;
  left: 20%;
  z-index: 5;

  height: 500px;
}

#journey__timeline-background__left__image__2 {
  top: 10%;
  left: 40%;
  z-index: 4;

  height: 250px;
}

#journey__timeline-background__left__image__3 {
  top: 30%;
  left: 40%;
  z-index: 3;

  height: 380px;
}

#journey__timeline-background__left__image__4 {
  top: 15%;
  left: 50%;
  z-index: 2;

  height: 350px;
}

#journey__timeline-background__left__image__5 {
  top: 45%;
  left: 20%;
  z-index: 1;

  height: 250px;
}
