.presse-eco {
  margin-top: 95px;
}
@media (--mobile) {
  .presse-eco {
    margin-top: 80px;
  }
}

@media (--mobile) {
  .presse-eco__title > .title > * {
    font-size: 19px;
  }
}

.presse-eco__title + .presse-eco__content {
  margin-top: 82px;
}
@media (--mobile) {
  .presse-eco__title + .presse-eco__content {
    margin-top: 44px;
  }
}

.presse-eco__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 46px;
}
@media (--mobile) {
  .presse-eco__content {
    grid-template-columns: 1fr;
    gap: 44px;
  }
}

.presse-eco__content-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 34px 35px;
}
@media (--mobile) {
  .presse-eco__content-row {
    grid-template-columns: 1fr;
    gap: 44px 35px;
  }
}

.presse-eco__content + .presse-eco__content-row {
  margin-top: 84px;
}
@media (--mobile) {
  .presse-eco__content + .presse-eco__content-row {
    margin-top: 44px;
  }
}

.presse-eco__item {
  position: relative;

  width: auto;
  height: auto;

  gap: 6px;

  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.presse-eco__item.presse-eco__item-card {
  padding: 16px;
}

.presse-eco__item.presse-eco__item-highlight {
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 14px;

  border: 1px solid transparent;
}
@media (--mobile) {
  .presse-eco__item.presse-eco__item-highlight {
    width: 100%;
  }
}

.presse-eco__item .presse-eco__item-title h3 {
  color: var(--White, #fff);
  font-family: var(--font-family);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.2px;
}

.presse-eco__item .presse-eco__item-title-subtitle > .title > * {
  display: flex;
  flex-direction: row;
  gap: 3px;

  color: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.16px;
}

.presse-eco__item.presse-eco__item-card .presse-eco__item-subtitle {
  color: var(--Light-Blue, #37c4e6);
  font-family: var(--font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.14px;
}

.presse-eco__item .presse-eco__item-content {
  color: #fff;
  font-family: var(--font-family);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.54px;
}
.presse-eco__item.presse-eco__item-card .presse-eco__item-content {
  color: rgba(255, 255, 255, 0.8);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: 0.16px;
}

.presse-eco__item .presse-eco__item-link {
  color: var(--Light-Blue, #37c4e6);
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.16px;

  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.presse-eco__item .presse-eco__item-link a {
  color: var(--Light-Blue);
  text-decoration: none;
}
.presse-eco__item .presse-eco__item-link a:hover {
  text-decoration: underline;
}
.presse-eco__item .presse-eco__item-link a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.presse-eco__item.presse-eco__item-card:hover .presse-eco__item-title {
  text-decoration: underline;
}

.presse-eco__item-image img {
  width: 100%;
  height: 167px;
  object-fit: cover;

  border-radius: 5px;
}
