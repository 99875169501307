.image-text:not(.image-text--bg-white) + .sharing-moments,
.cta + .sharing-moments {
  margin-top: 112px;
}
.prolonged-experience + .sharing-moments,
.hero-missions + .sharing-moments,
.prices + .sharing-moments {
  margin-top: 0;
}
@media (--tablet) {
  .image-text:not(.image-text--bg-white) + .sharing-moments,
  .cta + .sharing-moments {
    margin-top: 92px;
  }
}
@media (--mobile) {
  .image-text:not(.image-text--bg-white) + .sharing-moments,
  .cta + .sharing-moments {
    margin-top: 52px;
  }
}

.sharing-moments {
  position: relative;
  overflow: hidden;
  background-color: var(--white);

  color: var(--dark);
}

.sharing-moments__transition-left {
  position: absolute;
  top: 0;
  left: 0;
}

.sharing-moments__transition-right {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 1335px) {
  .sharing-moments__transition-left {
    display: none;
  }

  .sharing-moments__transition-right {
    display: none;
  }
}

.sharing-moments__container {
  padding: 72px 0;
}
@media (--mobile) {
  .sharing-moments__container {
    padding: 52px 0;
  }
}

.sharing-moments__container .ruler {
  margin: 0 auto;
}

.sharing-moments__container .heading__title {
  margin-top: 24px;
  text-align: center;
}

.sharing-moments__container .heading__title h2 {
  font-weight: 400;
  letter-spacing: 0.04em;
}

@media (--tablet) {
  .sharing-moments__container .heading__title h2 strong {
    font-weight: 400;
  }
}

* + .sharing-moments__description {
  margin-top: 24px;
}

.sharing-moments__description {
  display: flex;
  text-align: center;
  justify-content: center;
}

.sharing-moments__description p {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;

  max-width: 60ch;
}
@media (--tablet) {
  .sharing-moments__description p {
    font-size: 17px;
  }
}
@media (--mobile) {
  .sharing-moments__description p {
    font-size: 16px;
  }
}

.sharing-moments__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  justify-items: center;
  margin-top: 64px;
  margin-right: auto;
  margin-left: auto;
}

.sharing-moments__list--2-lines {
  grid-template-columns: repeat(3, 1fr);
}

.sharing-moments__list--2-lines > *:last-child {
  grid-column: 1 / -1;
}

.sharing-moments__list--2 {
  width: 50%;
  grid-template-columns: repeat(2, 1fr);
}
.sharing-moments__list--3 {
  width: 75%;
  grid-template-columns: repeat(3, 1fr);
}
@media (--tablet) {
  .sharing-moments__list {
    margin-top: 32px;
    grid-template-columns: repeat(2, 1fr);
  }
  .sharing-moments__list--2 {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  .sharing-moments__list--3 {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (--mobile) {
  .sharing-moments__list,
  .sharing-moments__list--2,
  .sharing-moments__list--3 {
    grid-template-columns: 1fr;
  }
}

.sharing-moments__item {
  --border-radius: 5px;

  position: relative;

  width: 100%;
  height: 100%;
  max-height: 369px;
  overflow: hidden;

  border-radius: var(--border-radius);
}
.sharing-moments__item--no-image {
  height: auto;
  background-color: var(--primary-600);
}
.sharing-moments__list--2-lines .sharing-moments__item:nth-of-type(4) {
  height: 280px;
}
@media (--mobile) {
  .sharing-moments__item {
    max-height: 369px;
  }
  .sharing-moments__list--2-lines .sharing-moments__item:nth-of-type(4) {
    height: 369px;
  }
}

.sharing-moments__item__gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background: radial-gradient(
    83% 50% at 50% 35%,
    rgba(4, 8, 20, 0) 0%,
    #040814 100%
  );
}
.sharing-moments__item--no-image .sharing-moments__item__gradient {
  display: none;
}
@media (--wider-than-mobile) {
  .sharing-moments__list--2-lines
    .sharing-moments__item:nth-of-type(4)
    .sharing-moments__item__gradient {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(4, 8, 20, 0.6) 50%,
      rgba(4, 8, 20, 0) 120%
    );
  }
}

.sharing-moments__item__image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.sharing-moments__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 600ms;
}
.sharing-moments__item--has-link:hover .sharing-moments__item__image img {
  transform: scale(1.03);
}

.sharing-moments__item__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (--wider-than-mobile) {
  .sharing-moments__list--2-lines
    > *:last-child
    .sharing-moments__item__content {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    text-align: center;
  }
}

.sharing-moments__item--no-image .sharing-moments__item__content {
  position: relative;
}

.sharing-moments__item__content__container {
  width: 100%;
  padding: 16px;
}

.sharing-moments__item__content__container a {
  text-decoration: none;
}
.sharing-moments__item__content__container a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 9;
}

.sharing-moments__item__content__container
  a:hover
  .sharing-moments__item__title {
  text-decoration: underline;
}

.sharing-moments__item__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);

  color: var(--white);
}

.sharing-moments__item__title + .sharing-moments__item__description {
  margin-top: 8px;
}

.sharing-moments__item__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: rgba(255, 255, 255, 0.8);
}
