.history {
  --vertical-padding: 130px;
  --item-width: 600px;
  --line-width: 116px;

  position: relative;

  background-color: black;
  overflow: hidden;
}
.quotation + .history {
  margin-top: 64px;
}
@media (--tablet) {
  .history {
    --vertical-padding: 96px;
  }
}
@media (--mobile) {
  .history {
    display: flex;
    flex-direction: column-reverse;
  }
  .quotation + .history {
    margin-top: 32px;
  }
}

@media (--mobile) {
  .history__container .max-width {
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
  }
}

.history__height {
  display: flex;
  flex-direction: row;
  width: 1000vw;
  height: 100%;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  opacity: 0;
  pointer-events: none;
  user-select: none;
}
@media (--mobile) {
  .history__height {
    display: none;
  }
}

.history__list {
  list-style: none;
}
@media (--mobile) {
  .history__list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;

    width: 100vw;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .history__list::before {
    content: '';

    position: absolute;
    top: 40px;
    left: var(--page-left-padding);
    right: 0;
    bottom: auto;

    height: 5px;
    width: var(--line-width);

    background-color: var(--blue-500);
  }
}

.history__item {
  width: var(--item-width);
}
@media (max-width: 1300px) {
  .history__item {
    padding-left: 28px;
  }
}

.history__list .history__item {
  position: absolute;
  top: 50%;
  left: var(--page-left-padding);

  opacity: 0;
  pointer-events: none;
  transform: translateY(-50%) translateY(16px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
}
.history__list .history__item--active {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(-50%);
}

.history__images {
  position: absolute;
  top: 0;
  left: 45%;
  right: 0;
  bottom: 0;

  list-style: none;
}

.history__images::after {
  content: '';

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
@media (--mobile) {
  .history__list .history__item {
    position: relative;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;

    flex: 1 0 100vw;
    width: 100vw;
    padding-top: 52px;
    padding-right: var(--page-right-padding);
    padding-bottom: 96px;
    padding-left: var(--page-left-padding);
    scroll-snap-align: center;
    pointer-events: auto;

    opacity: 1;
    transform: none;
  }

  .history__item__text__block {
    padding-top: 24px;
    padding-bottom: 150px;
    position: relative;
  }

  .history__images {
    z-index: 1;
    display: block;

    height: 250px;
    width: auto;

    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .history__images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .history__images::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.history__item__text__block {
  z-index: 2;
}

@media (--mobile) {
  .history .title--border-top::before {
    display: none;
  }
}

.history__item__text {
  line-height: 1.5;
}
.title + .history__item__text {
  margin-top: 25px;
}
.history__item__text p + p {
  margin-top: 16px;
}
.history__item__text p a {
  color: var(--blue-500);
  text-decoration: none;
}
.history__item__text p a:hover {
  text-decoration: underline;
}

.history__images__item {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.history__images__item--active {
  opacity: 1;
}

.history__images__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.history__dots {
  position: absolute;
  top: 50%;
  left: -32px;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  list-style: none;
  transform: translateY(-50%);
}
@media (max-width: 1300px) {
  .history__dots {
    left: 24px;
  }
}
@media (--mobile) {
  .history__dots {
    top: 39px;
    bottom: auto;
    left: calc(var(--page-left-padding) + var(--line-width) + 24px);

    flex-direction: row;

    transform: none;
  }
}

.history__dots__item {
  --size: 8px;

  position: relative;

  display: inline-block;
  height: var(--size);
  width: var(--size);

  background-color: #bbb;
  border-radius: 50%;

  transition: 200ms transform, 200ms background-color;
}
.history__dots__item::before {
  --size: 32px;

  position: absolute;
  top: 50%;
  left: 50%;

  content: '';
  display: inline-block;
  height: var(--size);
  width: var(--size);

  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.history__dots__item--active {
  background-color: var(--blue-500);
}
.history__dots__item--active,
.history__dots__item:hover {
  transform: scale(1.3);
}
.history__dots__item:active {
  transform: scale(1);
}
