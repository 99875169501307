.lang-switcher {
  position: relative;
  z-index: 2;

  display: flex;
  align-self: stretch;
  align-items: center;

  color: white;
  font-size: 15px;
}

.lang-switcher ul {
  display: flex;
  height: 100%;
  margin-top: -5px;
}

.lang-switcher__item {
  display: block;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;

  cursor: pointer;

  opacity: 0.5;

  transition: 100ms opacity;
}
.lang-switcher__item.lang-switcher__item--current,
.lang-switcher__item:hover {
  opacity: 1;
}
.lang-switcher__item a {
  --horizontal-padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  padding-top: 4px;

  color: white;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;

  user-select: none;
}
.js-focus-visible .lang-switcher__item a:focus:not(.focus-visible) {
  outline: none;
}

.header--dark .lang-switcher,
.header--dark .lang-switcher__item a {
  color: var(--primary-500);
}
