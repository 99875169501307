.experiences {
  margin-top: -260px;
}
@media (--mobile) {
  .experiences {
    margin-top: 0px;
    padding-top: 50px;
  }
}

.experiences__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  width: 100%;
  height: 100%;
}

@media (--tablet) {
  .experiences__container {
    grid-template-columns: 1fr;
    grid-gap: 28px;
  }
}

.experiences__item {
  --border-radius: 16px;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  height: 100%;

  border-radius: var(--border-radius);
}

.experiences__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.0666) 100%
  );
  backdrop-filter: blur(6px);
  border-radius: var(--border-radius);
  opacity: 0.75;
  transition: 200ms opacity;
}
.experiences__item:hover::before {
  opacity: 1;
}
.experiences__item:active::before {
  opacity: 0.8;
}

.experiences__item__wrap {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  width: 100%;
  height: 100%;
  padding-top: 72px;
  padding-bottom: 32px;
  overflow: hidden;
}

.experiences__item__tags {
  position: absolute;
  top: -4%;

  z-index: 999;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8.4px;
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  transform: rotate(-2.91deg);

  color: var(--white);
}

.experiences__item--violet .experiences__item__tags {
  background-color: var(--violet-500);
}

.experiences__item--green .experiences__item__tags {
  background-color: var(--green-500);
}

.experiences__item--raspberry .experiences__item__tags {
  background-color: var(--raspberry-500);
}

.experiences__item__logo {
  width: 268px;
  height: 100px;
  object-fit: contain;
  object-position: center center;
}
@media (--mobile) {
  .experiences__item__logo {
    max-width: 75%;
  }
}

.experiences__item__logo a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  content: '';
}
.experiences__item__logo img {
  width: 100%;
  height: 100%;

  object-fit: contain;
  user-select: none;
}

.experiences__item__description {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  padding-left: 16px;
  padding-right: 16px;

  color: rgba(255, 255, 255, 0.9);
}
