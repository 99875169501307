.review {
  --vertical-padding: 90px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  background-color: var(--background-color);
}
.review--theme-light {
  --text-color: black;
  --background-color: var(--white);
  --review-background-color: rgba(4, 8, 20, 0.06);
}
.review--theme-dark {
  --text-color: var(--white);
  --background-color: transparent;
  --review-background-color: #222632;
}

.review .max-width {
  max-width: 1800px;
}

.review__title {
  color: var(--text-color);
}

.review__title + .review__content {
  margin-top: 70px;
}

.review__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
  flex-wrap: wrap;
}

.review__item__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;

  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: var(--review-background-color);

  padding-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (--mobile) {
  .review__item__container {
    max-width: 100%;
  }
  .review__item__container:nth-child(even) {
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.review__item__review {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: center;
  gap: 8px;
  text-align: center;
}

@media (--mobile) {
  .review__item {
    flex-direction: column;
    gap: 45px;
  }
}

.review__item__text {
  font-size: 16px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--text-color);
}

.review__item__name {
  font-size: 16px;
  font-family: var(--font-family);
  font-style: italic;
  font-weight: 400;
  color: var(--text-color);
}

.review__item__text + .review__item__review {
  margin-top: 25px;
}

.review__item__container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0px 40px 30px 0;
  border-color: transparent var(--review-background-color) transparent
    transparent;
  transform: translateX(-100%);
}

@media (--mobile) {
  .review__item__container:nth-child(odd)::before {
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent
      var(--review-background-color);
    transform: translate(0%, -100%);
  }

  .review__item__container:nth-child(even)::before {
    left: auto;
    right: 0;
    border-width: 0 0 20px 20px;
    border-color: transparent transparent var(--review-background-color)
      transparent;
    transform: translate(0%, -100%);
  }
}
