.sticky-social {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1000;
}
@media screen and (max-width: 1300px) {
  .sticky-social {
    display: none;
  }
}

.sticky-social__list {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0;
  list-style: none;
  gap: 2px;
}

.sticky-social__list__item {
  display: flex;
  align-items: center;

  background: #272727;
}

.sticky-social__list__item a {
  padding: 8px 16px 8px 10px;
  background: #272727;
  transition: background-color 0.2s ease-in-out;
}
.sticky-social__list__item a:hover {
  background-color: var(--blue-500);
}
.sticky-social__list__item--facebook a:hover {
  background-color: #3b5998;
}
.sticky-social__list__item--X a:hover {
  background-color: #00acee;
}
.sticky-social__list__item--linkedin a:hover {
  background-color: #0077b5;
}
.sticky-social__list__item--youtube a:hover {
  background-color: #c4302b;
}
.sticky-social__list__item--instagram a:hover {
  background-color: #833ab4;
}

.sticky-social--team-building {
  max-width: 140px;
  padding: 12px 4px 12px 12px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  color: var(--blue-900);
  text-align: center;
  text-decoration: none;

  background-color: var(--blue-500);
  border-radius: 8px 0 0 8px;
}
.sticky-social--team-building:hover {
  background-color: var(--blue-400);
}
