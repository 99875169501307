.article-content__breadcrumb {
  list-style: none;
  text-align: center;
  color: black;
}
.article-content__breadcrumb li {
  display: inline-block;
}
.article-content__breadcrumb a {
  color: black;
  text-decoration: none;
}
.article-content__breadcrumb a:hover {
  text-decoration: underline;
}

.article-content__title {
  color: var(--primary-600);
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 40px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 75px;
  line-height: 1.2;
}
.article-content__breadcrumb + .max-width .article-content__title {
  margin-top: 24px;
}
@media (--tablet) {
  .article-content__title {
    margin-bottom: 50px;
    font-size: 32px;
    line-height: 1.4;
  }
  .article-content__breadcrumb + .max-width .article-content__title {
    margin-top: 16px;
  }
}
@media (--mobile) {
  .article-content__title {
    margin-bottom: 40px;
    font-size: 28px;
  }
}
