.contact__top__title h1 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);

  font-weight: 400;
  font-size: 52px;
  line-height: 78px;
  text-transform: uppercase;
}
@media (--tablet) {
  .contact__top__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .contact__top__title {
    font-size: 28px;
  }
}
