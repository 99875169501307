* + .more-escape-game {
  padding-top: 112px;
}
@media (--mobile) {
  * + .more-escape-game {
    padding-top: 64px;
  }
}

.more-escape-game--dark {
  background-color: black;
  --text-color: var(--white);
}

.more-escape-game--light {
  --text-color: black;
  background-color: var(--white);
}

* + .more-escape-game__description {
  margin-top: 16px;
}

.more-escape-game__title {
  color: var(--text-color);
}

.more-escape-game__description p {
  color: var(--text-color);

  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.more-escape-game__content {
  padding-bottom: 32px;
}

.more-escape-game__content a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

* + .more-escape-game__item__content {
  margin-top: 45px;
}

.more-escape-game__item__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
}

@media (--tablet) {
  .more-escape-game__item__content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (--mobile) {
  .more-escape-game__item__content {
    display: block;
  }

  * + .more-escape-game__item {
    margin-top: 24px;
  }

  .more-escape-game__item {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.more-escape-game__item {
  position: relative;
  width: 100%;
  height: 270px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 400ms ease-in-out;
}

.more-escape-game__tags {
  position: absolute;
  right: 0;
  bottom: -15px;
  left: 0;
  z-index: 4;

  width: fit-content;
  margin: 0 auto;
  padding: 9px;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 18px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;
}

.more-escape-game__tags--violet {
  background-color: var(--violet-500);
}

.more-escape-game__tags--green {
  background-color: var(--green-500);
}

.more-escape-game__tags--raspberry {
  background-color: var(--raspberry-500);
}

.more-escape-game__tags--blue {
  background-color: var(--blue-500);
}

@media (--tablet) {
  .more-escape-game__item {
    height: 330px;
    margin-bottom: 40px;
  }
}

.more-escape-game__item__image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
}

.more-escape-game__item:hover {
  transform: scale(1.05);
}

.more-escape-game__item__image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background: radial-gradient(
    170.53% 115.37% at 50% 50%,
    rgba(4, 8, 20, 0.9) 0%,
    rgba(4, 8, 20, 0) 100%
  );
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.more-escape-game__item:hover .more-escape-game__item__image__overlay {
  display: block;

  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.more-escape-game__item__image__overlay img {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 50%;
  height: auto;
  object-fit: cover;

  transform: translate(-50%, -50%);
}

@media (pointer: coarse) {
  .more-escape-game__item__image__overlay {
    opacity: 1;
    background: none;
  }
}
