.cta {
  position: relative;

  text-align: center;
}
* + .cta {
  margin-top: 112px;
}
.article-selection + .cta {
  margin-top: 49px;
}
@media (--mobile) {
  .slider + .cta {
    margin-top: 64px;
  }
  * + .cta {
    margin-top: 32px;
  }
}

.cta__gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: radial-gradient(
    33.25% 50% at 50% 50%,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.cta--no-title .cta__gradient {
  top: -100px;
}

.cta__background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.cta__background img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cta__background__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.cta__container {
  position: relative;

  max-width: 80ch;
  margin: auto;
}

*:not(.more-escape-game) + .cta--has-background {
  margin-top: 0;
}
.cta--has-background .cta__container {
  padding: 200px 0;
}
@media (--mobile) {
  .cta--has-background .cta__container {
    padding: 100px 0;
  }
}

.cta__title h2 {
  position: relative;

  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-weight: 400;
  font-size: 44px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
}
.cta__title h2 strong {
  color: var(--blue-500);
}
@media (--tablet) {
  .cta__title h2 strong {
    font-weight: 400;
  }
}
@media (--mobile) {
  .cta__title h2 {
    font-size: 26px;
  }
}

.cta__logo + .cta__title {
  margin-top: 24px;
}

.cta__title + .cta__description {
  margin-top: 24px;
}

.cta__description {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}
@media (--mobile) {
  .cta__description {
    font-size: 16px;
  }
}

.cta__button {
  margin: 24px auto;
}
