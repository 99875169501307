* + .video {
  padding-top: 65px;
}

.video {
  --aspect-ratio: 56.25%;
  --aspect-ratio-frac: 0.5625;

  position: relative;

  overflow: hidden;
}

.video--16by9 {
  --aspect-ratio: 56.25%;
}

.video--9by16 {
  --aspect-ratio: 177.77%;
}

.video--1by1 {
  --aspect-ratio: 100%;
}

.video--4by3 {
  --aspect-ratio: 75%;
}

@media (--mobile) {
  .image-text + .video {
    padding-top: 0;
  }
}

.video.video--is-mobile .video__play__wrap,
.video.video--ready.video--is-mobile .video__play__wrap {
  opacity: 0;

  transition: 300ms opacity;
}

.video__video.video--fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.video--background-white {
  background-color: var(--white);
  padding-bottom: 50px;
}

.video--background-black {
  background-color: var(--primary-600);
}

.video__content-container--dark {
  background-color: var(--primary-600);
  color: var(--white);
}

.video__content-container--white {
  background-color: var(--white);
  color: var(--primary-600);
}

.video__transitions {
  display: none;
}

.video__transitions .video__transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

.video + .video > .video__transitions {
  display: block;
}

.video__content-container h2:not(.video__text-description) {
  font-size: var(--title-large);
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  text-align: center;
  margin-top: 200px;
}

.video__content-container h2:not(.video-text__description) strong {
  color: #ffcb12;
}

.video__text-description {
  margin-top: 50px;

  font-weight: 400;
  font-size: var(--p-size);
  font-family: var(--font-family);
  text-align: center;
  line-height: 1.5;
}

.video__content-container + .aspect-ratio {
  margin-top: 50px;
}

@media (--mobile) {
  .video__content-container h2:not(.video__text-description) {
    margin-top: 80px;
  }

  .video__content-container + .aspect-ratio {
    margin-top: 50px;
  }
}

.video__video {
  position: relative;
  height: 0;
  padding-bottom: var(--aspect-ratio);
}

.video__play__wrap {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.video__video__wrap {
  position: absolute;
  top: 0;
  right: -3px;
  bottom: 0;
  left: -3px;

  overflow: hidden;
  border-radius: 5px;

  background-color: var(--primary-600);
}

.video__play {
  display: block;
  width: 100%;
  height: 100%;

  color: #ffffff;

  transition: 400ms opacity, 400ms transform;

  will-change: opacity, transform;
}
.video__video.video--started.video--playing .video__play {
  transform: scale(0.8);
  opacity: 0;

  transition: 300ms opacity, 300ms transform;

  pointer-events: none;
}

.video__video.video--started.video--playing .video__player__blur {
  width: calc(88px * 0.8);
  height: calc(88px * 0.8);
  opacity: 0;

  transition: 300ms all;

  pointer-events: none;
}

.video__player__blur {
  position: absolute;
  top: 50%;
  left: 50%;

  border-radius: 50%;

  transform: translate(-55%, -50%);

  width: 88px;
  height: 88px;
  transition: 300ms all;

  backdrop-filter: blur(4px);

  background-color: rgba(255, 255, 255, 0.2);
}

.video--hover .video__player__blur {
  width: calc(88px * 1.1);
  height: calc(88px * 1.1);

  transition: 300ms all;
}

.video__play > button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
}
.video__play > button:focus {
  outline: none;
}
.video__video.video--started.video--playing .video__play > button {
  cursor: default;
}

@media (--mobile) {
  .video__play > button {
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 17px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.video__play > button > .video__play__img {
  --size: 36px;

  display: flex;
  width: var(--size);
  height: var(--size);

  transition: 200ms transform;
}
@media (--mobile) {
  .video__play > button > .video__play__img {
    --size: 28px;
  }
}

.video__play > button > .video__play__img img {
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: 200ms transform;
}
.video__play > button:hover > .video__play__img {
  transform: scale(1.1);

  transition: 200ms transform;
}
.video__play > button:active > .video__play__img img {
  transform: scale(0.95);

  transition: 100ms transform;
}

.video__fullscreen {
  transition: 300ms opacity, 300ms transform;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: scale(1);

  padding: 20px;

  z-index: 4;
}

.video__fullscreen .video__fullscreen__img img {
  width: 20px;
  height: 20px;
}

.video__fullscreen:hover > .video__fullscreen__img {
  transform: scale(1.1);

  transition: 200ms transform;
}

.aspect-ratio.video--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
}

.aspect-ratio.video--fullscreen .video__player {
  box-shadow: none;
}

.aspect-ratio.video--fullscreen .video__video__wrap {
  border-radius: 0;
}

.aspect-ratio.video--fullscreen .aspect-ratio__wrap {
  width: 100%;
  max-width: calc(100vh * (1 / (var(--aspect-ratio-frac))));

  background: black;
}

.aspect-ratio.video--fullscreen .aspect-ratio__wrap__wrap {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);

  background: black;
}

.aspect-ratio.video--fullscreen .video__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid black;
  background: black;
}

.video__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.video__wrap {
  align-items: center;
  height: 100%;
}

.video__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);
  border-radius: 5px;
}

.video__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
  border-radius: 5px;
}

.video__player__cover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  content: '';
}

@media (--header-breakpoint) {
  .video__player__cover > img {
    bottom: 0;

    height: 100%;
  }

  .video__fullscreen {
    display: none;
  }
}

.video__video.video--ready .video__video__wrap {
  opacity: 1;

  transition: 200ms opacity;
}

@media (--mobile) {
  .video__video .video__video__wrap,
  .video__video.video--ready .video__video__wrap {
    opacity: 0;

    transition: 300ms opacity;
  }

  .video__video.video--started .video__video__wrap,
  .video__video.video--ready.video--started .video__video__wrap {
    opacity: 1;
  }
}

.video__video__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  height: 300px;

  transition: 500ms opacity;

  content: '';
}
.video__video.video--started.video--playing .video__video__wrap::after {
  transition: 2000ms opacity;
}

.video__video__wrap > * {
  position: relative;

  top: 50%;

  transform: translateY(-50%);
}

.video__control {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
}

.video__control__play-pause {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: default;
}
.video__video.video--started.video--playing .video__control__play-pause {
  z-index: 3;
}
.video__control__play-pause:focus {
  outline: none;
}

* + .video__legend {
  margin-top: 16px;
}

.video__legend p {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
