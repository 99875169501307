.Reservation__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 768px) {
  .Reservation__container {
    flex-direction: column;
  }
}

.Reservation__container > * {
  min-width: 50%;
}

@media (max-width: 768px) {
  .Reservation__container > * {
    min-width: 100%;
  }
}

.Reservation__item__image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Reservation__item__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    83.79% 51.13% at 50% 48.87%,
    rgba(4, 8, 20, 0) 0%,
    #040814 100%
  );
}

.Reservation__item__image > img {
  width: 100%;
  height: auto;
}

.Reservation__title {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls-large);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-transform: uppercase;
}

.Reservation__title h1 {
  font-size: 32px;
}

.Reservation__title h2 {
  /* Classic Purple */
  font-size: 24px;
  color: #59328a;
}

.Reservation__item__articles p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  letter-spacing: 0.02em;

  color: rgba(255, 255, 255, 0.9);
}
