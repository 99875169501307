.title {
  --color: var(--blue-500);
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);

  position: relative;

  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1.3;
}

.title--color-green {
  --color: var(--green-500);
}
.title--color-violet {
  --color: var(--violet-500);
}
.title--color-raspberry {
  --color: var(--raspberry-500);
}
.title--color-yellow {
  --color: var(--yellow-500);
}

.title--align-center {
  align-items: center;
  text-align: center;
}

.title--border-top::before {
  content: '';
  display: block;
  width: 140px;
  height: 7px;
  margin-bottom: 16px;

  background-color: var(--color);
}

.title__sub-title {
  color: var(--color);
}

.title--size-small {
  font-size: var(--title-small);
}

.title--size-medium {
  font-size: var(--title-medium);
  letter-spacing: 0.04em;
  font-weight: 600;
}

@media (--tablet) {
  .title--size-medium h1,
  h2 {
    font-weight: 400;
  }
  .contact__content__container > .title--size-medium h1,
  h2 {
    font-weight: 600;
  }
}

.subTitle--size-small {
  font-size: var(--subtitle-small);
}

.subTitle--size-medium {
  font-size: var(--subtitle-medium);
}

* + .title__sub-title {
  margin-top: 12px;
}

.title__sub-title h2 {
  max-width: inherit;
  font-size: inherit;
}

.title--title-upper-case .title-title {
  text-transform: uppercase;
}

.title--sub-title-upper-case .title__sub-title {
  text-transform: uppercase;
}

.title.title--color-green strong {
  color: var(--green-500);
}

.title.title--color-violet strong {
  color: var(--violet-500);
}

.title.title--color-raspberry strong {
  color: var(--raspberry-500);
}

.title.title--color-yellow strong {
  color: var(--yellow-500);
}
