.mobile-slider {
}

.mobile-slider .title::before {
  transition: background-color 200ms;
}

.mobile-slider h3 {
  color: black;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: 0.04em;
}

.mobile-slider__content__text {
  color: black;
}

.mobile-slider__swiper {
}
* + .mobile-slider__swiper {
  margin-top: 20px;
}

.mobile-slider__swiper__item {
}

.swiper-backface-hidden .swiper-slide.mobile-slider__swiper__item {
  transform: scale(0.8);
  transition: all 200ms linear;
  opacity: 0.5;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: -10px 0px 30px rgba(4, 8, 20, 0.2);

  &.swiper-slide-active {
    transform: scale(1);
    opacity: 1;
  }
}

.mobile-slider__swiper__item img {
  display: block;
  width: 100%;
  height: auto;
}

.mobile-slider__content {
  padding-left: var(--page-horizontal-padding);
  padding-right: var(--page-horizontal-padding);
}

@media (--tablet) {
  .mobile-slider__content {
    margin: auto;

    max-width: 500px;
  }
}

* + .mobile-slider__content {
  margin-top: 20px;
}

.mobile-slider__content__text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: var(--primary-600);
}
.mobile-slider__content__text * + p {
  margin-top: 12px;
}

.mobile-slider__content p strong {
  font-style: normal;
  color: var(--violet-500);
  font-weight: 600;
}
.mobile-slider__content--green p strong {
  color: var(--green-500);
}
.mobile-slider__content--raspberry p strong {
  color: var(--raspberry-500);
}

* + .mobile-slider__content__button {
  margin-top: 20px;
}
