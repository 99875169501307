.legal-mention {
  --padding-top: 60px;
  --selector-width: 270px;

  color: #040814;
}

@media (--mobile) {
  .legal-mention {
    --padding-top: 10px;
  }
}

* + .legal-mention {
  padding-top: var(--padding-top);
}

.legal-mention--is-first {
  padding-top: calc(var(--header-height) + var(--padding-top));
}

.legal-mention--selector .max-width {
  display: grid;
  grid-template-columns: var(--selector-width) 1fr;
  gap: 112px;
}

@media (--tablet) {
  .legal-mention .max-width {
    gap: 70px;
  }
}

@media (--mobile) {
  .legal-mention .max-width {
    display: block;
  }
}

/* SELECTOR */

@media (--mobile) {
  .legal-mention__left {
    display: none;
  }
}

.legal-mention__left__selector {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: var(--selector-width);
}

.legal-mention__left__selector--top {
  top: 0;
}

.legal-mention__left__selector--bottom {
  bottom: 0;
}

.legal-mention__left__selector--middle {
  position: fixed;
  top: calc(var(--header-height));
  height: calc(100vh - var(--header-height) - 16px * 2);
}

.legal-mention__left__selector--middle .legal-mention__left__selector__content {
  overflow-y: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* HIDE SCROLL BAR */
.legal-mention__left__selector--middle
  .legal-mention__left__selector__content::-webkit-scrollbar {
  display: none;
}

.legal-mention__left__selector__content__button {
  text-align: left;
  width: 100%;
}

.legal-mention__left__selector__content__button,
.legal-mention__left__selector p {
  background-color: #f2f5f9;
  padding: 14px 20px 10px 20px;
}

.legal-mention__left__selector p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.legal-mention__left__selector__content__button {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  transition: all 0.2s ease-in-out;
}

.legal-mention__left__selector__content__button:hover {
  background-color: rgba(55, 196, 230, 0.1);
  color: var(--blue-500);
}

.legal-mention__left__selector__content__button--active {
  background-color: rgba(55, 196, 230, 0.1);
  color: var(--blue-500);
}

/* CONTENT */

.legal-mention__content__line {
  border-bottom: 7px solid var(--blue-500);
  width: 20%;
}

@media (--mobile) {
  .legal-mention__content__line {
    border-bottom: 4px solid var(--blue-500);
    width: 15%;
  }
}

.legal-mention__content h1 {
  margin-top: 15px;
  font-weight: 600;
  font-size: 24px;
  color: var(--blue-500);
}

@media (--mobile) {
  .legal-mention__content h1 {
    font-size: 16px;
  }
}

* + .legal-mention__content h2 {
  margin-top: 15px;
}

@media (--mobile) {
  * + .legal-mention__content h2 {
    margin-top: 15px;
  }
}

.legal-mention__content h2 {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-weight: 400;
  font-size: 64px;
  line-height: 82px;
  max-width: 50%;
}

@media (--tablet) {
  .legal-mention__content h2 {
    max-width: 100%;
  }
}

@media (--mobile) {
  .legal-mention__content h2 {
    font-size: 30px;
  }
}

.legal-mention__content__section {
  width: 80%;
}

@media (--tablet) {
  .legal-mention__content__section {
    width: 100%;
  }
}

* + .legal-mention__content__section:not(:first-of-type) {
  margin-top: 30px;
}

.legal-mention__content__section h3 {
  font-weight: 700;
  font-size: 16px;
}

* + .legal-mention__content__section__content {
  margin-top: 30px;
}

.legal-mention__content__section__content p:not(:first-of-type) {
  margin-top: 30px;
}

.legal-mention__content__section__content p,
.legal-mention__content__section__content li {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@media (--mobile) {
  .legal-mention__content__section__content p,
  .legal-mention__content__section__content li {
    font-size: 14px;
  }
}

.legal-mention__content__section__content ul {
  margin-left: 20px;
}

* + .legal-mention__content__section__content li {
  margin-top: 15px;
}

.legal-mention__content__section__content a {
  color: var(--blue-500);
}

.legal-mention__button {
  margin-top: 30px;
}
