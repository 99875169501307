.page {
  position: relative;
  overflow: hidden;
}

.page--background-dark-blue-gradient {
  background-color: var(--primary-500);
  background-image: linear-gradient(
    to bottom,
    #060f1b,
    var(--primary-500) 940px
  );
}

.page--background-white-carpet {
  position: relative;

  background-color: white;
}

.page--background-white-carpet::before {
  --width: 720px;
  --height: 644px;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  content: '';

  background-image: url(/backgrounds/white.jpg);
  background-position: top center;
  background-size: var(--width) var(--height);
  background-repeat: repeat-x;
  opacity: 0.2;
}

.page__wrap {
  position: relative;
}

.faq + .page__bottom-margin {
  margin-top: 50px;
}

.article-selection + .page__bottom-margin {
  margin-top: 80px;
}

.texte + .page__bottom-margin {
  margin-top: 92px;
}
@media (--tablet) {
  .texte + .page__bottom-margin {
    margin-top: 64px;
  }
}
@media (--mobile) {
  .texte + .page__bottom-margin {
    margin-top: 32px;
  }
}
