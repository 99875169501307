.faq__title + .faq__content {
  margin-top: 20px;
}

@media (--tablet) {
  .faq__title + .faq__content {
    margin-top: 6px;
  }
}

.faq__title + .faq__subtitle {
  margin-top: 15px;
  max-width: 70ch;
  line-height: 1.2;
}

@media (--tablet) {
  .faq__title + .faq__subtitle {
    margin-top: 10px;
  }
}

.faq__subtitle + .faq__button {
  margin-top: 20px;
}

.faq__content {
  width: 45%;
  text-align: start;
}

@media (--tablet) {
  .faq__content {
    width: 100%;
  }
}

.faq__content__item {
  position: relative;
  user-select: none;
}

/* * + .faq__content__item {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
} */

.faq__content__item::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: solid 0.5px var(--white);
  opacity: 0.5;
}

.faq__content__item__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  transition: opacity 100ms;
  padding: 16px 0;
  position: relative;
}

.faq__content__item__header:hover {
  opacity: 0.8;
}

.faq__content__item__header__title__link:hover {
  cursor: pointer;
}

.faq__content__item__header__title__link::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;

  opacity: 0.5;
}

.faq__content__item__header__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.35;

  letter-spacing: 0.01em;
  width: 85%;
}

.faq__content__item__header__icon {
  max-width: 36px;
  max-height: 36px;
}
@media (--mobile) {
  .faq__content__item__header__icon {
    min-width: 26px;
    min-height: 26px;
  }
}

.faq__content__item__header__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.faq__content__item__content {
  overflow-y: hidden;
}

.faq__content__item__content > p + ul {
  padding-top: 11px;
}

.faq__content__item__content > ul > li {
  margin-left: 20px;
}

.faq__content__item__content,
.faq__content__item__content p {
  line-height: 1.4;
}
