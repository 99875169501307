.newsletter-form__label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  letter-spacing: 0.01em;
}
.newsletter-form__sub-text {
  margin-top: 16px;
  font-weight: 300;
  font-size: 13px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: var(--white);

  opacity: 0.6;
}

.newsletter-form__input {
  position: relative;

  width: 100%;
}
@media (--mobile) {
  .newsletter-form__input {
    margin-left: auto;
    margin-right: auto;
  }
}

* + .newsletter-form__input {
  margin-top: 24px;
}

@keyframes newsletter-form-input-flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}

.newsletter-form__input__input {
  width: 100%;
  height: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 50px;

  color: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  background: linear-gradient(
    91.23deg,
    rgba(255, 255, 255, 0.2) -1.61%,
    rgba(255, 255, 255, 0.08) 103.65%
  );
  border-radius: 5px;

  -webkit-appearance: none;
}
.newsletter-form--state-loading .newsletter-form__input__input {
  cursor: default;
  animation-name: newsletter-form-input-flash;
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.newsletter-form__input input::placeholder {
  color: var(--white);
  font-size: 14px;
}
.newsletter-form__input input:focus {
  outline: none;
}

.newsletter-form__submit {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 16px;
  padding-left: 24px;

  cursor: pointer;
}
.newsletter-form--state-loading .newsletter-form__submit {
  pointer-events: none;
}

.newsletter-form__submit svg {
  transition: 200ms transform;

  user-select: none;
}

.newsletter-form__submit:hover svg {
  transform: scale(1.15);
}

.newsletter-form__submit:active svg {
  transform: scale(1);

  transition: 100ms transform;
}

.newsletter-form__message {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.01em;
}
.newsletter-form__message--success {
  font-family: var(--font-family-heading);
  word-spacing: var(--font-family-heading-ws);
  letter-spacing: var(--font-family-heading-ls);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
}

* + .newsletter-form__message {
  margin-top: 12px;
}
