.header-light {
  --header-padding-top: 16px;
  --logo-width: 170px;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
@media (--tablet) {
  .header-light {
    --logo-width: 150px;
  }
}
@media (--mobile) {
  .header-light {
    --logo-width: 120px;
  }
}

.header-light .max-width {
  display: grid;
  grid-template-columns: 1fr var(--logo-width) 1fr;
  align-items: center;
  height: 100%;
  gap: 8px;
}
@media (--mobile) {
  .header-light .max-width {
    display: flex;
  }
  .header-light .max-width > div:first-child {
    display: none;
  }
}

.header-light__logo {
  display: block;
  width: var(--logo-width);
  padding-top: var(--header-padding-top);
  padding-bottom: var(--header-padding-top);
}

.header-light__logo img {
  width: 100%;
  height: auto;

  object-fit: contain;
}

.header-light__actions {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
