.contact__content {
  height: 100%;
}

.contact__content__container {
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: flex-start;
  justify-content: flex-start;
}

.contact__content__container__title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

* + .contact__content__container__description {
  margin-top: 24px;
}

.contact__content__container p + p {
  margin-top: 24px;
}

.contact__content__container__description em {
  font-style: normal;
  text-decoration: underline;
}
* + .contact__content__container__link {
  margin-top: 24px;
}

.contact__content__container__link
  .button--theme-transparent
  .button__right-icon
  svg {
  --size: 24px;
}

.contact__content__container__description {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}
